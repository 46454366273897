<template>
  <div class="app-menu">
    <div class="app-menu__header">
      <img src="@/assets/img/gpnlogo3.png" alt="none" class="app-menu-logo" @click="navigateTo('/')" />
      <img
        class="app-menu-close"
        alt="close-button"
        @click="closePopup"
        :src="require('@/assets/img/close-button.svg')"
      />
    </div>
    <div class="app-menu__links">
      <div class="menu-link-divblocks"><span class="menu-items" @click="navigateTo('/about')">ABOUT</span></div>
      <div class="menu-link-divblocks"><span class="menu-items" @click="navigateTo('/portfolio')">PORTFOLIO</span></div>
      <div class="menu-link-divblocks"><span class="menu-items" @click="navigateTo('/news')">NEWS</span></div>
      <div class="menu-link-divblocks"><span class="menu-items" @click="navigateTo('/insight')">INSIGHT</span></div>
      <div class="menu-link-divblocks"><span class="menu-items" @click="navigateTo('/jobs')">JOBS</span></div>
      <div class="menu-link-divblocks"><span class="menu-items" @click="navigateTo('/legal')">LEGAL</span></div>
      <div class="menu-link-divblocks"><span class="menu-items" @click="navigateTo('/contact')">CONTACT</span></div>
    </div>
    <div v-if="footerLinksLength" class="app-menu__footer">
      <div class="app-menu-socialicons">
        <a :href="footerLinks[0].field_follow_fb" target="_blank" rel="noopener noreferrer">
          <img alt="Vue logo" src="@/assets/img/facebook_blue.svg" class="app-menu-social-logo" />
        </a>
        <a :href="footerLinks[0].field_follow_twitter" target="_blank" rel="noopener noreferrer">
          <img alt="Vue logo" src="@/assets/img/twitter_blue.svg" class="app-menu-social-logo" />
        </a>
        <a :href="footerLinks[0].field_follow_instagram" target="_blank" rel="noopener noreferrer">
          <img alt="Vue logo" src="@/assets/img/ig.svg" class="app-menu-social-logo" />
        </a>
        <a :href="footerLinks[0].field_follow_youtube" target="_blank" rel="noopener noreferrer">
          <img alt="Vue logo" src="@/assets/img/youtube.svg" class="app-menu-social-logo" />
        </a>
        <a :href="footerLinks[0].field_follow_linkedin" target="_blank" rel="noopener noreferrer">
          <img alt="Vue logo" src="@/assets/img/linkedin.svg" class="app-menu-social-logo" />
        </a>
      </div>
      <div class="app-menu-rightfooter">
        <p class="app-menu-gdprights">&copy; GDP Venture All Rights Reserved</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'AppMenu',
  methods: {
    ...mapActions('footer', ['getFooterLinks']),
    closePopup() {
      this.closeMenu()
    },
    toHome() {
      this.$router.push('/')
    },
    navigateTo(path) {
      this.$router.push(path, () => {})
      document.body.style.overflow = 'auto'
    }
  },
  computed: {
    ...mapState('footer', ['footerLinks']),
    footerLinksLength() {
      return !!this.footerLinks.length
    }
  },
  props: {
    closeMenu: {
      type: Function,
      required: true
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/colors.scss';
.app-menu {
  background-color: $primary-white;
  height: 100vh;
  text-align: center;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 20px;
    .app-menu-logo {
      height: 30px;
      cursor: pointer;
    }
    .app-menu-close {
      border-radius: 50%;
      border: solid 2px $menu-close-border;
      color: $dark-blue-color;
      background-color: primary-white;
      cursor: pointer;
      width: 65px;
      height: 65px;
    }
    .app-menu-close:hover {
      border: solid 3px $dark-blue-color;
      transition-duration: 0.4s;
      transition-delay: 0s;
    }
  }
  &__links {
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    .menu-link-divblocks {
      display: block;
      margin: 37px;
      content: '';
    }
    .menu-items {
      cursor: pointer;
      text-decoration: none;
      font-family: GothamMedium;
      color: $dark-blue-color;
    }
  }
  &__footer {
    margin: auto;
    left: 0;
    right: 0;
    position: absolute;
    bottom: 30px;
    .app-menu-socialicons {
      display: flex;
      justify-content: center;
      margin-top: 0px;
      .app-menu-social-logo {
        padding: 0px 15px 0px 0px;
        cursor: pointer;
      }
    }
    .app-menu-rightfooter {
      padding-top: 15px;
    }
    .app-menu-gdprights {
      padding: 0px 15px 0px 15px;
      font-family: GothamBook;
      color: $dark-blue-color;
    }
  }
}
////Media Queries using mixins
@import '@/assets/scss/media-query-mixins.scss';
.app-menu {
  position: relative;
  @media (max-width: $max-width-iphone6-7-8) and (orientation: 'portrait') {
    &__header {
      .app-menu-logo {
        width: 60px;
        height: 40px;
      }
      .app-menu-close {
        font-size: 12px;
        width: 30px;
        height: 30px;
      }
    }
    &__links {
      font-size: 13px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      .menu-link-divblocks {
        margin: 25px;
      }
    }
    &__footer {
      .app-menu-gdprights {
        font-size: 10px;
      }
    }
  }
  @media (min-width: $min-width-iphoneXS) and (max-width: $max-width-iphone-11-XR-XSmax) and (orientation: 'portrait') {
    &__header {
      .app-menu-logo {
        width: 75px;
        height: 50px;
      }
      .app-menu-close {
        font-size: 16px;
        width: 35px;
        height: 35px;
      }
    }
    .menu-items {
      font-size: 18px;
    }
    &__footer {
      .app-menu-gdprights {
        font-family: GothamBook;
        color: dark-cerulean;
        font-size: 10px;
      }
      .app-menu-social-logo {
        padding-left: 20px !important;
      }
    }
  }
  @include iphone-landscape {
    &__header {
      padding-top: 10px;
      margin-left: 14px;
      margin-right: 14px;
      .app-menu-logo {
        height: 40px;
      }
      .app-menu-close {
        height: 30px;
      }
    }
    .menu-link-divblocks {
      margin: 5px !important;
    }
    .menu-items {
      font-size: 12px;
    }
    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      right: 20px;
      bottom: 0px;
      .app-menu-socialicons {
        .app-menu-social-logo {
          height: 20px;
        }
        .app-menu-gdprights {
          padding-top: 20px;
        }
      }
      .app-menu-gdprights {
        font-size: 10px;
      }
    }
  }
  @include ipad-portrait {
    &__header {
      padding-top: 23px;
      margin-left: 34px;
      margin-right: 34px;
      .app-menu-logo {
        width: 75.7px;
        height: 54.5px;
      }
      .app-menu-close {
        font-size: 25px;
        width: 50px;
        height: 50px;
      }
    }
    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 50px;
      margin-right: 50px;
      bottom: 40px;
      .app-menu-gdprights {
        font-size: 10px;
      }
    }
  }
  @include ipad-10-landscape {
    &__header {
      margin-left: 64px;
      margin-right: 64px;
      padding-left: 0;
      padding-right: 0;
      .app-menu-logo {
        height: 55px;
      }
      .app-menu-close {
        font-size: 22px;
        width: 50px;
        height: 50px;
      }
    }
    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 80px;
      margin-right: 80px;
      .app-menu-gdprights {
        font-size: 10px;
      }
    }
  }
  @include computers {
    &__header {
      padding-top: 23px;
      padding-left: 0;
      padding-right: 0;
      margin-left: 64px;
      margin-right: 64px;
      .app-menu-logo {
        height: 55px;
      }
      .app-menu-close {
        font-size: 23px;
        width: 45px;
        height: 45px;
      }
    }
    .menu-items {
      font-size: 18px;
    }
    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 80px;
      margin-left: 80px;
      .app-menu-socialicons {
        padding-top: 0px;
      }
      .app-menu-gdprights {
        justify-content: center;
        font-size: 14px;
      }
    }
  }
  @include larger-screens {
    &__header {
      padding-top: 40px;
      margin-left: 64px;
      margin-right: 64px;
      padding-left: 0;
      .app-menu-logo {
        height: 70px;
      }
      .app-menu-close {
        font-size: 35px;
        width: 65px;
        height: 65px;
      }
    }
    .menu-items {
      font-size: 25px;
    }
    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 80px;
      margin-left: 80px;
    }
  }
}
</style>
