<template>
  <div class="close-container" @click="clickOnClose">
    <img class="close-container__btn" alt="close-button" :src="require('@/assets/img/close-button.svg')" />
  </div>
</template>
<script>
export default {
  name: 'CloseButton',
  props: {
    clickOnClose: {
      type: Function,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/colors.scss';
.close-container {
  display: inline-block;
  &__btn {
    border-radius: 50%;
    border: solid 2px $menu-close-border;
    color: $dark-blue-color;
    background-color: primary-white;
    &:hover {
      border: solid 3px $dark-blue-color;
      transition-duration: 0.4s;
      transition-delay: 0s;
    }
  }
}
</style>
