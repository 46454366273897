<template>
  <div class="home-banner">
    <img :src="bannerImage" alt="Avatar" class="home-banner__image" />
    <h1
      class="home-banner__text"
      :key="bannerText"
      :class="scrollDirection ? 'bottomToTop' : 'topToBottom'"
      v-html="bannerText"
    ></h1>
    <div class="home-banner__bt">
      <button class="home-banner-button" @click="navigateCurrentPortfolio(bannerButton)">
        MORE ABOUT {{ bannerButton }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HomeBanner',
  props: {
    bannerText: {
      type: String,
      required: true
    },
    bannerButton: {
      type: String,
      required: true
    },
    bannerImage: {
      type: String,
      required: true
    },
    scrollDirection: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    navigateCurrentPortfolio(bannerButton) {
      this.$router.push({ name: 'portfoliodetail', params: { currentIndex: bannerButton } })
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/colors.scss';
@import '@/assets/scss/fonts.scss';
@import '@/assets/scss/media-query-mixins.scss';
.home-banner {
  height: 100vh;
  position: relative;
  display: block;
  width: 100%;
  background-color: black;
  z-index: 0;
  &__image {
    z-index: -1;
    width: inherit;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.5;
    -webkit-backdrop-filter: blur(1.5px);
    backdrop-filter: blur(1.5px);
    object-fit: cover;
  }
  &__text {
    text-transform: capitalize;
    color: $primary-white;
    text-align: center;
    padding-top: 30vh;
    font-size: 48px;
    line-height: 1.18;
    width: 66%;
    margin-left: auto;
    margin-right: auto;
    z-index: 9;
  }
  &__bt {
    text-align: center;
    margin-top: 30px;
    .home-banner-button {
      border-radius: 35px;
      border: solid 1px $primary-white;
      background-color: transparent;
      color: $primary-white;
      font-size: 16px;
      font-weight: 500;
      font-family: GothamMedium;
      height: 38px;
      z-index: 11;
      text-transform: uppercase;
      padding: 0 16px 0 16px;
    }
  }
  .home-banner-button:hover {
    background-color: $primary-blue;
    transition: 0.25s ease-in;
    border: none;
  }
}
.home-banner {
  @include iphone-5se-potrait {
    &__text {
      padding-top: 100px !important;
      font-size: 20px !important;
    }
  }
  @media (max-width: $max-width-iphone-11-XR-XSmax) and (orientation: 'portrait') {
    text-align: left;
    height: 100vh;
    &__text {
      width: 80%;
      text-align: left;
      font-size: 36px;
      padding-top: 150px;
      margin-left: 18px;
      @include iphone-portrait {
        width: 90%;
      }
    }
    &__bt {
      text-align: left;
      margin-left: 16px;
      margin-top: 15px;
      padding-top: 0;
    }
  }
  @include iphone-landscape {
    &__text {
      font-size: 30px;
      padding-top: 60px;
    }
    &__bt {
      margin-top: 10px;
      .home-banner-button {
        font-size: 12px;
      }
    }
  }
  @include ipad-portrait {
    text-align: center;
    height: 100vh !important;
    &__image {
      left: 0;
      height: 100%;
    }
    &__text {
      text-align: center;
      padding-top: 35vh;
      font-size: 48px;
      line-height: 1.18;
      width: 702px;
      margin-left: auto;
      margin-right: auto;
    }
    &__bt {
      text-align: center;
      .home-banner-button {
        line-height: 1.38;
        letter-spacing: normal;
      }
    }
  }
  @include iphone-portrait {
    height: 100vh;
    &__image {
      left: 0;
      height: 100%;
    }
  }
  @include ipad-landscape {
    height: 100vh;
    &__image {
      height: 100%;
    }
    &__text {
      width: 70%;
      padding-top: 30vh;
    }
  }
  @include larger-screens {
    &__text {
      padding-top: 40vh;
    }
  }
}
.bottomToTop {
  animation: bottom-top-transition 1s;
  @keyframes bottom-top-transition {
    0% {
      transform: translate3d(0, 20vh, 0);
      opacity: 0;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
}
.topToBottom {
  animation: top-bottom-transition 1s;
  @keyframes top-bottom-transition {
    0% {
      transform: translateY(-30%);
      opacity: 0;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      transform: translatY(0);
      opacity: 1;
    }
  }
}
</style>
