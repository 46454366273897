import api from '@/utils/http-api'
import apiConst from '@/utils/api-constants'

export default {
  namespaced: true,
  state: {
    value: 'my home value',
    posts: [],
    company: [],
    insights: [],
    news: []
  },
  getters: {
    value: state => {
      return state.value
    }
  },
  mutations: {
    updateValue(state, payload) {
      state.value = payload
    },
    setPosts(state, payload) {
      state.posts = payload
    },
    setCompany(state, payload) {
      payload.sort(function(a, b) {
        return a.title.localeCompare(b.title)
      })
      state.company = payload
    },
    setInsights(state, payload) {
      state.insights = payload
    },
    setNews(state, payload) {
      state.news = payload
    }
  },
  actions: {
    getCompany({ commit }, callBack) {
      const { getCompany } = apiConst.home
      api.getRequest(getCompany).then(response => {
        commit('setCompany', response.data)
        callBack && callBack()
      })
    },
    getInsights({ commit }) {
      const { getInsights } = apiConst.home
      api.getRequest(getInsights).then(response => {
        commit('setInsights', response.data)
      })
    },
    getNews({ commit }) {
      const { getNews } = apiConst.home
      api.getRequest(getNews).then(response => {
        commit('setNews', response.data)
      })
    }
  }
}
