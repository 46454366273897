import api from '@/utils/http-api'
import apiConst from '@/utils/api-constants'

export default {
  namespaced: true,
  state: {
    resultArray: undefined,
    companyArray: undefined,
    leaderArray: undefined
  },
  getters: {},
  mutations: {
    setResultArray(state, payload) {
      state.resultArray = payload
    },
    setCompanyArray(state, payload) {
      state.companyArray = payload
    },
    setLeaderArray(state, payload) {
      state.leaderArray = payload
    }
  },
  actions: {
    getCompanyResult({ commit }, payload) {
      const { getCompanyResult } = apiConst.search
      api.getRequest(getCompanyResult(payload)).then(response => {
        commit('setCompanyArray', response.data)
      })
    },
    getLeaderResult({ commit }, payload) {
      const { getLeaderResult } = apiConst.search
      api.getRequest(getLeaderResult(payload)).then(response => {
        commit('setLeaderArray', response.data)
      })
    },
    getSearchResult({ commit }, payload) {
      const { getSearchResult } = apiConst.search
      api.getRequest(getSearchResult(payload)).then(response => {
        commit('setResultArray', response.data)
      })
    },
    clearSearchHistory({ commit }) {
      commit('setResultArray', undefined)
      commit('setLeaderArray', undefined)
      commit('setCompanyArray', undefined)
    }
  }
}
