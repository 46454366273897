<template>
  <div class="app-legal">
    <div class="app-legal-discription">
      <h1 class="app-legal-discription__legal-heading">Legal</h1>
      <div class="app-legal-discription__privacy-policy">
        <h3 class="app-legal-heading">Privacy Policy</h3>
        <p class="app-legal-detail">
          Welcome and thank you for visiting GDP Ventures, a site presented to you by PT Global Digital Prima (“GDP” or
          ”we” or “We”). This Privacy Policy informs you of our policies and procedures regarding the collection, use,
          and disclosure of the information that we collect and receive from users or audience of our website as well as
          internet services that we offer (collectively the “Services”).
        </p>
        <p class="app-legal-detail">
          We may update this Privacy Policy at any time, by posting the amended version on this site. We will announce
          any material changes to this Privacy Policy through an alert on our websites and/or via email.
        </p>
        <p class="app-legal-detail">
          By accessing our website, you are agreeing to be bound by the term of this Privacy Policy, all applicable laws
          and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do
          not agree with any of these terms, you are prohibited from using or accessing this website. The materials
          contained in this application are protected by applicable copyright and trade mark law.
        </p>
        <h3 class="app-legal-heading" id="information">Information Collection and Use</h3>
        <p class="app-legal-detail">
          <b class="app-legal-detail__bold" id="UsersRegistration">Users Registration Information.</b> To obtain receive
          Services, we ask you to provide us with personal information, such as email address. We may also ask you to
          personalize your user experience with other information such as your location, and your individual
          preferences.
        </p>
        <p class="app-legal-detail">
          <b class="app-legal-detail__bold" id="InformationWe">Information We Collect Automatically.</b> We
          automatically collect certain information to help us understand how you use the Services (which we will refer
          to in this Privacy Policy collectively as “Usage Data”). For example, each time you visit the our website we
          may automatically collect your IP address, browser and computer type, access time, the webpage from which you
          came, and the webpage(s) that you access during your visit. We may combine Usage Data with Users Registration
          Information in a manner that enables us to trace Usage Data to an individual user. Some web browsers may have
          “do not track” settings. Our website do not honor “do not track” settings.
        </p>
        <p class="app-legal-detail">
          We also may use cookies (which are small amounts of data sent from a web server to your browser that are
          stored on your computer’s hard drive) to keep track of your use of the Services, to validate your identity and
          to improve the quality of the Services. Generally, you can set your browser not to accept cookies or to notify
          you if you are sent a cookie, giving you the opportunity to choose whether or not to accept the cookie. Please
          note that if you do set your browser not to accept cookies, certain Services may not function properly.
        </p>
        <p class="app-legal-detail">
          <b class="app-legal-detail__bold" id="UsageInformation">Usage Information.</b> We also collect information
          about how you use our Services, including how you view and interact with content and advertisements, the parts
          of our Services that you use, information you search for, content that you view and shared.
        </p>
        <p class="app-legal-detail">
          <b class="app-legal-detail__bold" id="Surveys">Surveys.</b> From time to time, we may provide you the
          opportunity to participate in surveys via our Services. If you participate, we may request certain additional
          information from you.
        </p>
        <h3 class="app-legal-heading" id="howwe">How we use the information</h3>
        <p class="app-legal-detail">
          We may use information we collect including your Users Registration Information such as email address and
          information from your use of our Services to provide and improve our Services, develop new offerings and
          enhance and personalize your experience on our website, including the content that you see.
        </p>
        <p class="app-legal-detail">
          We may also use your Users Registration Information to send you important messages regarding the Services that
          we think may be of interest to you. If you decide at any time that you no longer wish to receive
          communications from us, please follow the unsubscribe instructions provided in those communications.
        </p>
        <h3 class="app-legal-heading" id="disclosure">Disclosure of the Information We Collect</h3>
        <p class="app-legal-detail">
          <b class="app-legal-detail__bold" id="WithYour"> With Your Consent.</b> We may disclose any information we
          collect with third parties when we receive your consent to do so.
        </p>
        <p class="app-legal-detail">
          <b class="app-legal-detail__bold" id="Non-Private">Non-Private or Non-Personal Information.</b> We also may
          disclose aggregated and non-personally identifiable information that we collect. Any such disclosure will not
          include non-public, personal information regarding individual users.
        </p>
        <p class="app-legal-detail">
          <b class="app-legal-detail__bold" id="ThirdParty">Third Party Services.</b> We use third parties to providing
          and improving the Services such as maintenance, database management and web analytics companies. If these
          third parties receive non-public, personal information about you, they will be obligated not to disclose or
          use it for any purpose.
        </p>
        <p class="app-legal-detail">
          <b class="app-legal-detail__bold" id="Compliancewith">Compliance with Laws and Law Enforcement.</b> GDP
          cooperates with government and law enforcement officials and private parties to enforce and comply with the
          law. We will disclose any information about you to government or law enforcement officials or private parties
          as we, in our sole discretion, believe necessary or appropriate to respond to claims and legal process
          (including but not limited to subpoenas), to protect the property and rights of GDP or a third party, to
          protect the safety of the public or any person or to prevent or restrict what we consider to be, or to pose a
          risk of being, illegal, unethical or legally actionable activity.
        </p>
        <p class="app-legal-detail">
          <b class="app-legal-detail__bold" id="InternationalTransfers">International Transfers.</b> GDP may sell,
          transfer or otherwise share some or all of its assets, including the information identified in this Privacy
          Policy, in connection with a merger, acquisition, reorganization or sale of assets or in the event of
          bankruptcy.
        </p>
        <h3 class="app-legal-heading" id="informationsecurity">Information Security</h3>
        <p class="app-legal-detail">
          GDP team reasonable physical, technological and administrative security measures to protect the information it
          collects. We all know the Internet, or method of electronic storage, is 100% secure. Therefore, while we use
          acceptable means to protect the information we collect, we cannot guarantee its absolute security.
        </p>
        <p class="app-legal-detail">
          If GDP learns of a security breach that results in the unauthorized disclosure of your Users Registration
          Information, we may attempt to notify you electronically through the Services so that you can take appropriate
          protective steps. GDP may also send an e-mail to you at the e-mail address you have provided to us for a
          notification pursuant to this paragraph. If you wish to receive written (non-electronic) notice of any
          security breach, please send a request for such notice, including your physical address, to
          privacy@gdpventures.com.
        </p>
        <h3 class="app-legal-heading" id="linkss">Links</h3>
        <p class="app-legal-detail">
          Our website may contain links to other website. We are not responsible for the privacy practices of any such
          other website (whether accessed through an advertisement, service or content link) and urge you to review such
          practices prior to submitting any information to such websites.
        </p>
        <h3 class="app-legal-heading" id="terms">Term of Use</h3>
        <p class="app-legal-detail">
          Welcome to www.gdpventure.com. This website is owned and operated by PT Global Digital Prima (“GDP” or “We” or
          “We” or “us”). By visiting our website and accessing the information, resources, services, products, and tools
          we provide, you understand and agree to accept and adhere to the following terms and conditions as stated in
          this policy (hereafter referred to as ‘User Agreement’), along with the terms and conditions as stated in our
          Privacy Policy (please refer to the Privacy Policy section below for more information). We reserve the right
          to change this User Agreement from time to time without notice. You acknowledge and agree that it is your
          responsibility to review this User Agreement periodically to familiarize yourself with any modifications. Your
          continued use of this website after such modifications will constitute acknowledgment and agreement of the
          modified terms and conditions.
        </p>
        <h3 class="app-legal-heading" id="responsible">Responsible Use and Conduct</h3>
        <p class="app-legal-detail">
          By visiting our website and accessing the information, resources, services, products, and tools we provide for
          you, either directly or indirectly (hereafter referred to as ‘Resources’), you agree to use these Resources
          only for the purposes intended as permitted by (a) the terms of this User Agreement, and (b) applicable laws,
          regulations and generally accepted online practices or guidelines.
        </p>
        <h3 class="app-legal-heading" id="Wherein">Wherein, you understand that:</h3>
        <p class="app-legal-detail">
          a. In order to access our Resources, you may be required to provide certain information about yourself (such
          as email address) as part of the registration process, or as part of your ability to use the Resources. You
          agree that any information you provide will always be accurate, correct, and up to date.
        </p>
        <p class="app-legal-detail">
          b. You are responsible for maintaining the confidentiality of any information associated with You.
          Accordingly, you are responsible for all activities of yours using the Resources.
        </p>
        <p class="app-legal-detail">
          c. Accessing (or attempting to access) any of our Resources by any means other than through the means we
          provide, is strictly prohibited. You specifically agree not to access (or attempt to access) any of our
          Resources through any automated, unethical or unconventional means.
        </p>
        <p class="app-legal-detail">
          d. Engaging in any activity that disrupts or interferes with our Resources, including the servers and/or
          networks to which our Resources are located or connected, is strictly prohibited.
        </p>
        <p class="app-legal-detail">
          e. Attempting to copy, duplicate, reproduce, sell, trade, or resell our Resources is strictly prohibited.
        </p>
        <p class="app-legal-detail">
          f. You are solely responsible any consequences, losses, or damages that we may directly or indirectly incur or
          suffer due to any unauthorized activities conducted by you, as explained above, and may incur criminal or
          civil liability.
        </p>
        <p class="app-legal-detail">
          g. We may provide various open communication tools on our website, such as blog comments, blog posts, public
          chat, forums, message boards, newsgroups, product ratings and reviews, various social media services, etc. You
          understand that generally we do not pre-screen or monitor the content posted by users of these various
          communication tools, which means that if you choose to use these tools to submit any type of content to our
          website, then it is your personal responsibility to use these tools in a responsible and ethical manner. By
          posting information or otherwise using any open communication tools as mentioned, you agree that you will not
          upload, post, share, or otherwise distribute any content that:<br />
          i. Is illegal, threatening, defamatory, abusive, harassing, degrading, intimidating, fraudulent, deceptive,
          invasive, racist, or contains any type of suggestive, inappropriate, or explicit language;<br />
          ii. Infringes on any trademark, patent, trade secret, copyright, or other proprietary right of any party;<br />
          iii. Contains any type of unauthorized or unsolicited advertising;<br />
          iv. Impersonates any person or entity, including any GDP employees or representatives. We have the right at
          our sole discretion to remove any content that, we feel in our judgment does not comply with this User
          Agreement, along with any content that we feel is otherwise offensive, harmful, objectionable, inaccurate, or
          violates any 3rd party copyrights or trademarks. We are not responsible for any delay or failure in removing
          such content. If you post content that we choose to remove, you hereby consent to such removal, and consent to
          waive any claim against us.
        </p>
        <p class="app-legal-detail">
          h. We do not assume any liability for any content posted by you or any other 3rd party users of our website.
          However, any content posted by you using any open communication tools on our website, provided that it doesn’t
          violate or infringe on any 3rd party copyrights or trademarks, becomes the property of GDP, and as such, gives
          us a perpetual, irrevocable, worldwide, royalty-free, exclusive license to reproduce, modify, adapt,
          translate, publish, publicly display and/or distribute as we see fit. This only refers and applies to content
          posted via open communication tools as described, and does not refer to information that is provided as part
          of the registration process, necessary in order to use our Resources. All information provided as part of our
          registration process is covered by our privacy policy.
        </p>
        <p class="app-legal-detail">
          i. You agree to indemnify and hold harmless GDP and its parent company and affiliates, and their directors,
          officers, managers, employees, donors, agents, and licensors, from and against all losses, expenses, damages
          and costs, including reasonable attorneys’ fees, resulting from any violation of this User Agreement or the
          failure to fulfill any obligations relating to your account incurred by you or any other person using your
          account. We reserve the right to take over the exclusive defense of any claim for which we are entitled to
          indemnification under this User Agreement. In such event, you shall provide us with such cooperation as is
          reasonably requested by us.
        </p>
        <h3 class="app-legal-heading" id="Privacy">Privacy</h3>
        <p class="app-legal-detail">
          Your privacy is very important to us, which is why we’ve created a separate Privacy Policy in order to explain
          in detail how we collect, manage, process, secure, and store your private information. Our privacy policy is
          included under the scope of this User Agreement. To read our privacy policy in its entirety, click here.
        </p>
        <h3 class="app-legal-heading" id="Limitation">Limitation of Warranties</h3>
        <p class="app-legal-detail">
          By using our website, you understand and agree that all Resources we provide are “as is” and “as available”.
          This means that we do not represent or warrant to you that: <br />i) the use of our Resources will meet your
          needs or requirements.<br />
          ii) the use of our Resources will be uninterrupted, timely, secure or free from errors.<br />
          iii) the information obtained by using our Resources will be accurate or reliable, and <br />
          iv) any defects in the operation or functionality of any Resources we provide will be repaired or corrected.
        </p>
        <h3 class="app-legal-heading" id="Furthermore">Furthermore, you understand and agree that:</h3>
        <p class="app-legal-detail">
          v) any content downloaded or otherwise obtained through the use of our Resources is done at your own
          discretion and risk, and that you are solely responsible for any damage to your computer or other devices for
          any loss of data that may result from the download of such content.<br />
          vi) no information or advice, whether expressed, implied, oral or written, obtained by you from GDP or through
          any Resources we provide shall create any warranty, guarantee, or conditions of any kind, except for those
          expressly outlined in this User Agreement.
        </p>
        <h3 class="app-legal-heading" id="Limitationof">Limitation of Liability</h3>
        <p class="app-legal-detail">
          In conjunction with the Limitation of Warranties as explained above, you expressly understand and agree that
          any claim against us shall be limited to the amount you paid, if any, for use of products and/or services. GDP
          will not be liable for any direct, indirect, incidental, consequential or exemplary loss or damages which may
          be incurred by you as a result of using our Resources, or as a result of any changes, data loss or corruption,
          cancellation, loss of access, or downtime to the full extent that applicable limitation of liability laws
          apply.
        </p>
        <h3 class="app-legal-heading" id="Copyrights">Copyrights/Trademarks</h3>
        <p class="app-legal-detail">
          All content and materials available on www.gdpventure.com, including but not limited to text, graphics,
          website name, code, images and logos are the intellectual property of GDP, and are protected by applicable
          copyright and trademark law. Any inappropriate use, including but not limited to the reproduction,
          distribution, display or transmission of any content on this site is strictly prohibited, unless specifically
          authorized by GDP.
        </p>
        <h3 class="app-legal-heading" id="Termination">Termination of Use</h3>
        <p class="app-legal-detail">
          You agree that we may, at our sole discretion, suspend or terminate your access to all or part of our website
          and Resources with or without notice and for any reason, including, without limitation, breach of this User
          Agreement. Any suspected illegal, fraudulent or abusive activity may be grounds for terminating your
          relationship and may be referred to appropriate law enforcement authorities. Upon suspension or termination,
          your right to use the Resources we provide will immediately cease, and we reserve the right to remove or
          delete any information that you may have on file with us, including any account or login information.
        </p>
        <h3 class="app-legal-heading" id="Governing">Governing Law</h3>
        <p class="app-legal-detail">
          This website is controlled by GDP from our offices located in [*], Indonesia. It can be accessed by most
          countries around the world. As each country has laws that may differ from those of Indonesia, by accessing our
          website, you agree that the laws of Indonesia will apply to all matters relating to the use of this website.
          Furthermore, any action to enforce this User Agreement shall be brought in the courts located in Central
          Jakarta. You hereby agree to personal jurisdiction by such courts, and waive any jurisdictional, venue, or
          inconvenient forum objections to such courts.
        </p>
        <h3 class="app-legal-heading" id="Guarantee">Guarantee</h3>
        <p class="app-legal-detail">
          Unless otherwise expressed, GDP expressly disclaims all warranties and conditions of any kind, whether express
          or implied, including, but not limited to the implied warranties and conditions of merchantability, fitness
          for a particular purpose and non-infringement.
        </p>
        <h3 class="app-legal-heading" id="question">Questions</h3>
        <p class="app-legal-detail">
          If you have any questions about this Privacy Policy, please feel free to contact us by e-mail at
          privacy@gdpventure.com
        </p>
      </div>
    </div>
    <div class="app-legal-links">
      <h2 class="app-legal-links__anchor">Privacy Policy</h2>
      <h2><a href="#information" class="app-legal-links__anchorlink">Information Collection and Use</a></h2>
      <span
        ><a href="#UsersRegistration" class="app-legal-links__anchorlinks">• Users Registration Information.</a></span
      >
      <br />
      <span>
        <a href="#InformationWe" class="app-legal-links__anchorlinks">• Information We Collect Automatically.</a>
      </span>
      <br />
      <span><a href="#UsageInformation" class="app-legal-links__anchorlinks">• Usage Information.</a></span> <br />
      <span><a href="#Surveys" class="app-legal-links__anchorlinks">• Surveys.</a></span> <br />
      <h2><a href="#howwe" class="app-legal-links__anchorlink">How we use the information</a></h2>
      <h2><a href="#disclosure" class="app-legal-links__anchorlink">Disclosure of the Information We Collect</a></h2>
      <span><a href="#WithYour" class="app-legal-links__anchorlinks"> • With Your Consent.</a></span> <br />
      <span
        ><a href="#Non-Private" class="app-legal-links__anchorlinks"
          >• Non-Private or Non-Personal Information.</a
        ></span
      >
      <br />
      <span><a href="#ThirdParty" class="app-legal-links__anchorlinks">• Third Party Services.</a></span> <br />
      <span>
        <a href="#Compliancewith" class="app-legal-links__anchorlinks">• Compliance with Laws and Law Enforcement.</a>
      </span>
      <br />
      <span><a href="#InternationalTransfers" class="app-legal-links__anchorlinks">• International Transfers.</a></span>
      <br />
      <h2><a href="#informationsecurity" class="app-legal-links__anchorlink">Information Security</a></h2>
      <h2><a href="#linkss" class="app-legal-links__anchorlink">Links</a></h2>
      <h2><a href="#terms" class="app-legal-links__anchorlink">Term of Use</a></h2>
      <h2><a href="#responsible" class="app-legal-links__anchorlink">Responsible Use and Conduct</a></h2>
      <h2><a href="#Wherein" class="app-legal-links__anchorlink">Wherein, you understand that:</a></h2>
      <h2><a href="#Privacy" class="app-legal-links__anchorlink">Privacy</a></h2>
      <h2><a href="#Limitation" class="app-legal-links__anchorlink">Limitation of Warranties</a></h2>
      <h2>
        <a href="#Furthermore" class="app-legal-links__anchorlink">Furthermore, you understand and agree that:</a>
      </h2>
      <h2><a href="#Limitationof" class="app-legal-links__anchorlink">Limitation of Liability</a></h2>
      <h2><a href="#Copyrights" class="app-legal-links__anchorlink">Copyrights/Trademarks</a></h2>
      <h2><a href="#Termination" class="app-legal-links__anchorlink">Termination of Use</a></h2>
      <h2><a href="#Governing" class="app-legal-links__anchorlink">Governing Law</a></h2>
      <h2><a href="#Guarantee" class="app-legal-links__anchorlink">Guarantee</a></h2>
      <h2><a href="#question" class="app-legal-links__anchorlink">Questions</a></h2>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Legal'
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/colors.scss';
@import '@/assets/scss/fonts.scss';
@import '@/assets/scss/media-query-mixins.scss';
:target::before {
  content: '';
  display: block;
  height: 100px; /* fixed header height*/
  margin: -60px 0 0; /* negative fixed header height */
}
.app-legal {
  background-color: $page-background-color;
  padding: 80px 80px 80px 80px;
  display: flex;
  .app-legal-discription {
    width: 70%;
    margin-right: 60px;
    &__legal-heading {
      font-size: 32px;
      font-weight: 500;
      line-height: 1.38;
      text-align: left;
      color: $primary-black;
      font-family: GothamMedium;
    }
    &__privacy-policy {
      margin-top: 38px;
    }
    .app-legal-heading {
      font-size: 20px;
      line-height: 28px;
      color: $primary-black;
      font-family: GothamMedium;
    }
    .app-legal-detail {
      font-size: 16px;
      color: $primary-black;
      font-family: GothamBook;
      line-height: 28px;
      margin-bottom: 20px;
      &__bold {
        font-family: GothamBook;
      }
    }
  }
  .app-legal-links {
    margin-top: 85px;
    &__anchor {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      font-family: GothamMedium;
      color: $dark-blue-color;
      text-decoration: none;
    }
    &__anchorlink {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      font-family: GothamMedium;
      color: $dark-blue-color;
      margin-top: 20px;
      text-decoration: none;
    }
    &__anchorlinks {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      font-family: GothamBook;
      color: $dark-blue-color;
      text-decoration: none;
    }
  }
}
.app-legal {
  @include iphone-portrait {
    padding: 0;
    .app-legal-discription {
      &__legal-heading {
        font-size: 24px;
      }
      &__privacy-policy {
        margin-top: 19px;
        font-size: 16px;
      }
      .app-legal-detail {
        font-size: 14px;
        line-height: 20px;
        &__bold {
          font-size: 14px;
        }
      }
    }
    .app-legal-discription {
      width: 100%;
      margin: 37px 16px 70px 16px;
    }
    .app-legal-links {
      display: none;
    }
  }
  @include ipad-portrait {
    padding: 0;
    .app-legal-discription {
      width: 100%;
      margin: 46px 50px 70px 51px;
    }
    .app-legal-links {
      display: none;
    }
  }
  @include iphone-landscape {
    padding: 0;
    .app-legal-discription {
      width: 100%;
      margin: 46px 50px 70px 51px;
    }
    .app-legal-links {
      display: none;
    }
  }
}
</style>
