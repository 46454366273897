<template>
  <div class="leader-card">
    <div class="carousel  leader-card-curosal" data-interval="false" data-ride="carousel">
      <div class=" curosal-card">
        <div
          v-for="(leaderdetail, index) in leader"
          :key="index"
          class="carousel-item"
          :class="{ active: currentActiveIndex === index }"
        >
          <LeaderCard
            :leaderName="leader[currentActiveIndex].field_leader_name"
            :leaderInfo="leader[currentActiveIndex].field_leader_designation"
            :leaderImage="baseUrl + leader[currentActiveIndex].field_leader_image"
          />
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleControls"
        data-bs-slide="prev"
        @click="previousLeader"
      >
        <img src="@/assets/img/leader-previous.png" alt="none" class="next-previous" />
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleControls"
        data-bs-slide="next"
        @click="nextLeader"
      >
        <img src="@/assets/img/leader-next.png" alt="none" class="next-previous" />
      </button>
    </div>
    <div v-if="leaderDetailData" class="leader-description-detail">
      <div
        class="leader-card-info"
        v-for="transitionIndex in 1"
        v-bind:key="`${transitionRenderKey}-${transitionIndex}`"
        v-html="leaderDetailDescription"
        style="white-space: pre-line"
      ></div>
      <div class="leader-card-image-linkdien">
        <a :href="leader[currentActiveIndex].field_leader_linkedin" target="_blank" rel="noopener noreferrer">
          <img src="@/assets/img/linkedin-icon-black.svg" alt="none" class="leader-card-linkdein" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { BACKEND_BASE_IMG_PATH } from '@/utils/constants.js'
import LeaderCard from '@/components/LeaderCard.vue'
export default {
  name: 'LeaderDetail',
  props: ['idleads'],
  components: {
    LeaderCard
  },
  data() {
    return {
      currentActiveIndex: 0,
      baseUrl: BACKEND_BASE_IMG_PATH,
      transitionRenderKey: 0
    }
  },
  methods: {
    ...mapActions('about', ['getLeader']),
    nextLeader() {
      this.transitionRenderKey = this.transitionRenderKey + 1
      if (this.leader.length - 2 < this.currentActiveIndex) {
        this.currentActiveIndex = 0
      } else {
        this.currentActiveIndex = this.currentActiveIndex + 1
      }
    },
    previousLeader() {
      this.transitionRenderKey = this.transitionRenderKey + 1
      if (this.currentActiveIndex < 1) {
        this.currentActiveIndex = this.leader.length - 1
      } else {
        this.currentActiveIndex = this.currentActiveIndex - 1
      }
    }
  },
  computed: {
    ...mapState('about', ['detail', 'leader']),
    leaderDetailDescription() {
      return this.leader[this.currentActiveIndex].field_leader_description
    },
    leaderDetailData() {
      return (this.leader && this.leader[0] && this.leader[0].field_leader_description) || ''
    }
  },
  mounted() {
    this.getLeader()
    for (let i = 0; i < this.leader.length; i++) {
      if (this.leader[i].field_id === this.$route.params.idleads) {
        this.currentActiveIndex = i
        break
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/colors.scss';
@import '@/assets/scss/fonts.scss';
@import '@/assets/scss/media-query-mixins.scss';
.leader-card {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 75px 105px 125px 100px;
  background-color: $page-background-color;
  @include ipad-landscape {
    margin-right: 80px;
    margin-left: 0;
  }
  @include ipad-default-landscape {
    margin-left: 90px;
  }
  .leader-card-curosal {
    .curosal-card {
      padding-right: 120px;
      padding-left: 120px;
      .carousel-item {
        transition-delay: 1s;
      }
    }
    .next-previous {
      height: 66px;
      width: 66px;
    }
  }
  .leader-description-detail {
    @include computers {
      margin: 0 30px 0 63px;
    }
    @include ipad-default-landscape {
      margin-left: 0;
    }
  }
  .leader-card-info {
    animation: left-right-transition 1s;
    line-height: 1.67;
    letter-spacing: 0.29px;
    color: $light-gray;
    font-size: 18px;
    font-family: GothamBook;
    @include larger-screens {
      width: 80%;
    }
    @include ipad-landscape {
      font-size: 16px;
    }
  }
  .leader-card-description {
    margin-left: 59px;
    height: 100%;
    text-align: left;
    .leader-card-description-info {
      font-size: 18px;
      line-height: 1.67;
      letter-spacing: 0.29px;
      color: $light-gray;
      font-family: GothamBook;
    }
  }
  .leader-card-linkdein {
    margin-top: 20px;
    cursor: pointer;
  }
}
.leader-card {
  @include ipad-10-landscape {
    margin: 65px 80px 0 80px !important;
    .leader-card-curosal {
      .curosal-card {
        padding-right: 60px;
        padding-left: 60px;
      }
    }
    .leader-card-description {
      margin-left: 59px;
    }
  }
  @include ipad-landscape {
    margin-right: 120px;
    margin-left: 10px;
    .curosal-card {
      padding-right: 65px !important;
      padding-left: 70px !important;
    }
  }
  @include ipad-portrait {
    flex-direction: column;
    margin: 30px 80px 30px 80px !important;
    .leader-card-info {
      text-align: center;
    }
    .leader-card-curosal {
      .curosal-card {
        padding-right: 80px !important;
        padding-left: 80px !important;
      }
      .next-previous {
        height: 66px !important;
        width: 66px !important;
      }
    }
    .leader-card-image-linkdien {
      text-align: center;
    }
    .leader-card-description {
      margin-left: 0;
      text-align: center;
      .leader-card-description-info {
        text-align: center;
      }
      .leader-card-linkdein {
        align-items: center !important;
      }
    }
  }
  @include iphone-portrait {
    flex-direction: column;
    margin: 20px 16px 0 16px;
    .leader-card-info {
      text-align: center;
    }
    .leader-card-curosal {
      .curosal-card {
        padding-right: 45px;
        padding-left: 45px;
      }
      .next-previous {
        height: 30px;
        width: 30px;
      }
    }
    .leader-card-image-linkdien {
      text-align: center;
      margin-bottom: 30px;
    }
    .leader-card-description {
      margin-left: 0;
      text-align: center;
      .leader-card-description-info {
        text-align: center;
      }
    }
  }
}
@keyframes left-right-transition {
  0% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}
::v-deep .leader-image {
  @include ipad-portrait {
    width: 318px !important;
    height: 343px !important;
  }
}
::v-deep .overlay-white {
  @include ipad-portrait {
    width: 296px !important;
    height: 296px !important;
  }
}
.leader-card::v-deep .overlay-white {
  background-color: $primary-blue;
}
.leader-card::v-deep .leader-name {
  color: $dark-blue-color;
}
::v-deep .leader-image {
  width: auto !important;
}
</style>
