import api from '@/utils/http-api'
import apiConst from '@/utils/api-constants'

export default {
  namespaced: true,
  state: {
    footerLinks: []
  },
  getters: {},
  mutations: {
    setFooterLinks(state, payload) {
      state.footerLinks = payload
    }
  },
  actions: {
    getFooterLinks({ commit }) {
      const { getFooterLinks } = apiConst.footer
      api.getRequest(getFooterLinks).then(response => {
        commit('setFooterLinks', response.data)
      })
    }
  }
}
