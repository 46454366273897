<template>
  <div class="news-card">
    <div class="news-card__image-container">
      <img class="news-card__image" :src="imgUrl" />
    </div>
    <div class="news-card__col">
      <div class="news-card__type">
        <p class="news-card__type-text">{{ type }}</p>
      </div>
      <div class="news-card__title">
        <p class="news-card__title-text" v-html="title"></p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NewsCard',
  props: {
    type: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    imgUrl: {
      type: String,
      default: ''
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/colors';
@import '@/assets/scss/fonts';
@import '@/assets/scss/media-query-mixins';
p {
  margin: 0;
}
.news-card {
  cursor: pointer;
  max-width: 305px;
  height: 320px;
  border-radius: 10px;
  background-color: $primary-white;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0 0 10px;
  }
  @include ipad-landscape {
    max-width: 220px;
    max-height: 260px;
  }
  @include ipad-10-landscape {
    max-width: 220px;
    max-height: 270px;
  }
  @include iphone-portrait {
    background-color: $page-background-color;
    display: flex;
    max-height: 73px;
    max-width: 342px;
    border-radius: 0;
    &:hover {
      box-shadow: none;
    }
  }
  @include ipad-portrait {
    flex-direction: column;
    max-width: 342px;
    max-height: 210px;
    border-radius: 10px;
    background-color: $primary-white;
  }
  @include ipad-10-portrait {
    background-color: $page-background-color;
    display: flex;
    max-width: 342px;
    max-height: 73px;
    border-radius: 0;
  }
  @include ipad-default-portrait {
    max-height: 320px;
  }
  &__image {
    border-radius: 10px 10px 0 0;
    width: 100%;
    height: 170px;
    object-fit: cover;
    @include ipad-10-landscape {
      max-height: 130px !important;
      height: 130px !important;
    }
    @include ipad-landscape {
      max-height: 130px !important;
      height: 130px !important;
    }
    @include iphone-portrait {
      border-radius: 10px;
      height: 100%;
      width: 125px;
    }
    @include ipad-portrait {
      width: 100%;
      height: 120px;
      border-radius: 10px 10px 0 0;
    }
    @include ipad-10-portrait {
      border-radius: 8px !important;
      height: 100%;
      max-width: 125px;
    }
    @include ipad-default-portrait {
      height: 170px;
    }
  }
  &__col {
    @include ipad-landscape {
      height: 120px;
    }
    @include iphone-portrait {
      display: flex;
      flex-direction: column;
    }
    @include ipad-10-portrait {
      display: flex;
      flex-direction: column;
    }
  }
  &__type-text {
    text-align: left;
    margin: 10px 0 0 16px;
    object-fit: contain;
    font-family: GothamBook;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.85;
    letter-spacing: 0.33px;
    text-align: left;
    color: $light-gray;
    text-transform: uppercase;
    @include ipad-landscape {
      margin: 6px 0 0 12px;
      font-size: 13px;
      letter-spacing: 0.2px;
    }
    @include iphone-portrait {
      margin: 0 0 0 8px;
      font-size: 10px;
      letter-spacing: normal;
    }
    @include ipad-10-portrait {
      font-size: 10px;
      margin: 0 0 0 8px;
      letter-spacing: normal;
    }
    @include ipad-10-landscape {
      margin: 6px 0 0 12px !important;
    }
    @include ipad-portrait {
      font-size: 13px;
      padding-top: 5px;
    }
    @media (min-width: 897px) and(max-width:1023px) {
      margin-top: 0;
    }
  }
  &__title-text {
    margin: 8px 16.2px 11px 15.5px;
    object-fit: contain;
    font-family: GothamMedium;
    max-height: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 0.1px;
    text-align: left;
    color: $primary-black;
    padding-bottom: 10px;
    @include ipad-10-landscape {
      margin: 9px 12.5px 18px 12px;
      font-size: 18px;
      letter-spacing: 0.2px;
      max-height: 78px;
    }
    @include iphone-portrait {
      max-width: 209px;
      height: 40px;
      -webkit-line-clamp: 2;
      font-size: 16px;
      line-height: 1.2;
      margin: 5px 0 0 8px;
    }
    @include ipad-10-portrait {
      -webkit-line-clamp: 2;
      max-width: 209px;
      max-height: 56px;
      font-size: 16px;
      line-height: 1.25 !important;
      margin: 5px 0 0 8px;
    }
    @media (min-width: 897px) and(max-width:1023px) {
      -webkit-line-clamp: 2;
      max-height: 40px;
      font-size: 16px;
    }
    @include ipad-landscape {
      margin-left: 12px;
    }
  }
}
</style>
