<template>
  <div class="scroll-container">
    <div id="scrollIndicator"></div>
  </div>
</template>
<script>
export default {
  name: 'ScrollIndicator',
  methods: {
    scrollBar() {
      var scrollTop = document.documentElement['scrollTop'] || document.body['scrollTop']
      var scrollBottom =
        (document.documentElement['scrollHeight'] || document.body['scrollHeight']) -
        document.documentElement.clientHeight
      var scrollPercent = (scrollTop / scrollBottom) * 100 + '%'
      document.getElementById('scrollIndicator').style.setProperty('--scroll', scrollPercent)
    }
  },
  mounted() {
    document.addEventListener('scroll', this.scrollBar, { passive: true })
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.scrollBar)
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/colors';
.scroll-container {
  position: fixed;
  width: 100%;
  height: 8px;
  z-index: 50;
  background: $scroll-indicator-background-color;

  #scrollIndicator {
    --scroll: 0%;
    background-color: $footer-background-color;
    background: linear-gradient(to right, $primary-blue var(--scroll), transparent 0);
    position: fixed;
    width: 100%;
    height: 8px;
    z-index: 51;
  }
}
</style>
