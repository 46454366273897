<template>
  <div class="subscribe-popup__container">
    <div v-if="!showThankYouPopup" class="subscribe-popup">
      <div class="subscribe-popup__close-btn"><CloseButton :clickOnClose="closeSubscribePopup" /></div>
      <input
        class="subscribe-popup__inputField"
        :class="{ error: nameErrorFlag }"
        type="text"
        placeholder="Name"
        v-model="name"
      />
      <input
        class="subscribe-popup__inputField"
        :class="{ error: companyErrorFlag }"
        type="text"
        placeholder="Company"
        v-model="company"
      />
      <input
        class="subscribe-popup__inputField"
        :class="{ error: emailErrorFlag }"
        type="email"
        placeholder="Email"
        v-model="email"
      />
      <div class="google-captcha">
        <vue-recaptcha @verify="onVerify" :sitekey="siteKey" :loadRecaptchaScript="true"></vue-recaptcha>
      </div>
      <div class="subscribe-popup__button">
        <span class="subscribe-btn" @click="displayThankYouPopup">SUBSCRIBE</span>
      </div>
    </div>
    <!-- THANKYOU POPUP -->
    <transition name="fade">
      <div v-if="showThankYouPopup" class="thankyou-popup">
        <div class="thankyou-popup__circle">
          <img class="thankyou-popup__check" alt="gdp-logo" :src="require('@/assets/img/icon-check-blue.svg')" />
        </div>
        <div class="thankyou-popup__text">Thank You For Subscribing!</div>
      </div>
    </transition>
    <div v-if="showSubscribe || showThankYouPopup" @click="removeMask" class="mask"></div>
  </div>
</template>
<script>
import { SITE_KEY } from '@/utils/constants.js'
import VueRecaptcha from 'vue-recaptcha'
import { mapActions } from 'vuex'
import CloseButton from '@/components/CloseButton'
export default {
  name: 'SubscribePopup',
  data() {
    return {
      name: '',
      company: '',
      email: '',
      nameErrorFlag: false,
      companyErrorFlag: false,
      emailErrorFlag: false,
      showThankYouPopup: false,
      subscribeStatus: false,
      robot: false,
      siteKey: SITE_KEY
    }
  },
  components: {
    CloseButton,
    VueRecaptcha
  },
  methods: {
    ...mapActions('subscribe', ['postSubscribeDetails']),
    displayThankYouPopup() {
      this.validationCheck()
      if (!this.nameErrorFlag && !this.companyErrorFlag && !this.emailErrorFlag && this.robot) {
        this.showThankYouPopup = true
        this.subscribeStatus = true
        sessionStorage.subscribeStatus = this.subscribeStatus
        this.closeSubscribeBar()
      }
      let postBody = { name: this.name, company: this.company, email: this.email }
      this.postSubscribeDetails(postBody)
    },
    removeMask() {
      this.showThankYouPopup = false
      this.closeSubscribePopup()
    },
    validationCheck() {
      let mailRegEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      this.nameErrorFlag = this.name.length ? false : true
      this.companyErrorFlag = this.company.length ? false : true
      this.emailErrorFlag = this.email.length && mailRegEx.test(this.email) ? false : true
    },
    onVerify(response) {
      if (response) this.robot = true
    }
  },
  props: {
    showSubscribe: {
      type: Boolean,
      required: true
    },
    closeSubscribePopup: {
      type: Function,
      required: true
    },
    closeSubscribeBar: {
      type: Function,
      required: true
    }
  },
  watch: {
    name() {
      if (this.name.length && this.nameErrorFlag) this.nameErrorFlag = false
    },
    company() {
      if (this.company.length && this.companyErrorFlag) this.companyErrorFlag = false
    },
    email() {
      if (this.email.length && this.emailErrorFlag) this.emailErrorFlag = false
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/colors';
@import '@/assets/scss/fonts';
@import '@/assets/scss/media-query-mixins';
@import '@/assets/scss/placeholder-mixins';
.subscribe-popup__container {
  // For MASK
  .mask {
    cursor: pointer;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $mask-background-color;
  }
  // FOR SUBSCRIBE-POPUP
  .subscribe-popup {
    position: fixed;
    z-index: 100;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    border: 1px solid $whisper-gray;
    max-width: 418px;
    border-radius: 10px;
    background-color: $primary-white;
    text-align: center;
    @include iphone-portrait {
      min-width: 280px;
      max-width: 340px;
    }
    &__close-btn {
      cursor: pointer;
      margin: 30px;
      margin-bottom: 0;
      text-align: center;
    }
    &__inputField {
      margin: 20px 40px;
      border: 0;
      outline: 0;
      background: transparent;
      border-bottom: 0.1em solid $gray-44;
      width: 80%;
      -webkit-border-top-left-radius: 0;
      -webkit-border-top-right-radius: 0;
      -webkit-border-bottom-right-radius: 0;
      -webkit-border-bottom-left-radius: 0;
      -moz-border-radius-topleft: 0;
      -moz-border-radius-topright: 0;
      -moz-border-radius-bottomright: 0;
      -moz-border-radius-bottomleft: 0;
      @include iphone-portrait {
        margin-left: auto;
        margin-right: auto;
      }
    }
    .google-captcha {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      margin-right: 10px;
      align-items: center;
    }
    &__button {
      text-align: center;
      margin: 30px;
      cursor: pointer;
      .subscribe-btn {
        color: $primary-white;
        background-color: $primary-blue;
        font-size: 16px;
        font-weight: 500;
        font-family: GothamMedium;
        padding: 11px 25px 11px 25px;
        border-radius: 35px;
      }
    }
    & ::v-deep .close-container__btn {
      height: 50px;
      width: 50px;
      font-size: 25px;
      @include iphone-portrait {
        height: 30px;
        width: 30px;
        font-size: 12px;
      }
      @include ipad-10-portrait {
        height: 50px;
        width: 50px;
        font-size: 25px;
      }
    }
    .error {
      border-bottom: 1px solid red;
      &::placeholder {
        color: red;
      }
    }
    @include placeholder {
      width: 100%;
      opacity: 0.5;
      font-family: GothamMedium;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      color: $primary-black;
    }
    input {
      text-align: center;
    }
  }
  //FOR THANK YOU POPUP
  .thankyou-popup {
    position: fixed;
    z-index: 100;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    height: 320px;
    width: 274px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $primary-white;
    border-radius: 10px;
    &__circle {
      height: 121px;
      width: 121px;
      border: 5px solid $dark-blue-color;
      border-radius: 50%;
      margin: 57px 76px 30px 77px;
      .thankyou-popup__check {
        width: 67px;
        height: 48px;
        margin: 36px 27px 36px 26px;
      }
    }
    &__text {
      font-family: GothamMedium;
      font-size: 24px;
      font-weight: 500;
      color: $primary-black;
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    opacity: 1;
    transition: opacity 2s ease-in-out;
  }
  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }
}
</style>
