<template>
  <div class="header-main">
    <div class="gdp-header">
      <span class="gdp-header__logo-container">
        <img class="gdp-header__logo" alt="gdp-logo" :src="logoUrl" @click="navigateTo('/')" />
      </span>
      <ul class="gdp-header__list" :style="{ color: linkColor }">
        <li class="gdp-header__item">
          <a class="gdp-header__item-link" @click="navigateTo('/about')">ABOUT</a>
        </li>
        <li class="gdp-header__item">
          <a class="gdp-header__item-link" @click="navigateTo('/portfolio')">PORTFOLIO</a>
        </li>
        <li class="gdp-header__item">
          <a class="gdp-header__item-link" @click="navigateTo('/news')">NEWS</a>
        </li>
        <li class="gdp-header__item">
          <a class="gdp-header__item-link" @click="navigateTo('/insight')">INSIGHT</a>
        </li>
        <li class="gdp-header__item">
          <img class="search-icon" alt="search-icon" :src="searchIcon" @click="navigateTo('/search')" />
        </li>
        <li class="gdp-header__item">
          <img
            class="horizontal-ellipsis"
            alt="horizontal-dots"
            :src="horizontalDotsIcon"
            @click="navigateTo('/menu')"
          />
        </li>
        <li class="gdp-header__item">
          <img class="hamburger-icon" alt="hamburger" :src="hamburgerIcon" @click="navigateTo('/menu')" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Header',
  props: {
    logoUrl: {
      type: String,
      default: require('@/assets/img/gdp-blue-logo.svg')
    },
    searchIcon: {
      type: String,
      default: require('@/assets/img/search-icon-blue.svg')
    },
    horizontalDotsIcon: {
      type: String,
      default: require('@/assets/img/horizontal-dots-blue.svg')
    },
    hamburgerIcon: {
      type: String,
      default: require('@/assets/img/hamburger-button-blue.svg')
    },
    linkColor: {
      type: String,
      default: '#004B80'
    }
  },
  methods: {
    navigateTo(path) {
      this.$router.push(path, () => {})
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/fonts';
@import '@/assets/scss/colors';
@import '@/assets/scss/media-query-mixins';
@import '@/assets/scss/variables';
.gdp-header {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: GothamMedium;
  box-shadow: 0px 8px 8px -6px rgba(0, 0, 0, 0.5);
  &__logo-container {
    width: 50%;
    max-height: 100px;
  }
  &__logo {
    height: 57px;
    width: 80px;
    margin: 22px auto 22px 80px;
    float: left;
    cursor: pointer;
    @include iphone-portrait {
      height: 50px;
      margin: 20px auto 20px 16px;
      width: 69.5px;
    }
    @include iphone-landscape {
      height: 50px;
      margin: 20px auto 20px 16px;
      width: 69.5px;
    }
    @include ipad-portrait {
      height: 50px;
      margin: 20px auto 20px 50px;
      width: 69.5px;
    }
  }
  &__list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    padding: 0;
    margin: 0;
    margin-right: 80px;
    @include ipad-10-portrait {
      margin-right: 50px;
    }
    @include iphone-portrait {
      margin: 0;
    }
    @include iphone-landscape {
      margin: 0;
    }
    @include ipad-portrait {
      margin-right: 51px;
    }
    .gdp-header__item {
      list-style: none;
      padding: 0;
      margin-right: 0;
      a {
        color: inherit;
      }
      .gdp-header__item-link {
        margin: 0;
        text-decoration: none;
        letter-spacing: 1.4px;
        line-height: 20px;
        font-size: 14px;
        margin-left: 50px;
        cursor: pointer;
        @include iphone-portrait {
          display: none;
        }
        @include ipad-landscape {
          margin-left: 18px;
        }
        @include iphone-landscape {
          display: none;
        }
        @include ipad-portrait {
          display: none;
        }
      }
      .search-icon {
        height: 30px;
        width: 30px;
        margin-left: 50px;
        cursor: pointer;
        @include iphone-portrait {
          margin: 30px 20px 30px 0px;
        }
        @include iphone-landscape {
          margin: 30px 20px 30px 0px;
        }
        @include ipad-10-portrait {
          margin-right: 20px;
        }
        @include ipad-portrait {
          margin: 30px 20px 30px 0px;
        }
        @include ipad-landscape {
          height: 23px;
          width: 23px;
          margin-left: 36px;
        }
      }
      .horizontal-ellipsis {
        margin-left: 50px;
        cursor: pointer;
        @include iphone-portrait {
          display: none;
        }
        @include iphone-landscape {
          display: none;
        }
        @include ipad-portrait {
          display: none;
        }
        @include ipad-landscape {
          height: 23px;
          width: 44px;
          margin-left: 38px;
        }
      }
      .hamburger-icon {
        cursor: pointer;
        display: none;
        @include iphone-portrait {
          margin: 30px 16px 30px 0px;
          height: 30px;
          width: 30px;
          display: block;
        }
        @include iphone-landscape {
          margin: 30px 16px 30px 0px;
          height: 30px;
          width: 30px;
          display: block;
        }
        @include ipad-portrait {
          margin: 30px 0 30px 0px;
          height: 30px;
          width: 30px;
          display: block;
        }
        @include ipad-landscape {
          height: 23px;
          width: 44px;
          margin: 37px 20px 41px 0;
        }
      }
    }
  }
}
</style>
