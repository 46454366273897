<template>
  <div class="portfolio-detail" v-if="sortedcompany && sortedcompany[0]">
    <div class="portfolio-detail__change-page-ipad">
      <img class="navigation-icon" src="@/assets/img/portfolio-previous-3x.png" @click="previousPortfolio" />
      <a @click="navigateTo('/portfolio')"><p class="all-portfolio">SEE ALL PORTFOLIO</p></a>
      <img class="navigation-icon invert" src="@/assets/img/portfolio-previous-3x.png" @click="nextPortfolio" />
    </div>
    <div
      v-for="transitionIndex in 1"
      v-bind:key="`${transitionRenderKey}-${transitionIndex}`"
      class="portfolio-detail__img-container re-animation"
      :style="{
        backgroundImage: `url( ${baseUrl + sortedcompany[currentActiveIndex].field_portfolio_detail_image})`
      }"
    >
      <div class="gradient"></div>
    </div>
    <div
      class="portfolio-detail__desc"
      v-for="transitionIndex in 1"
      v-bind:key="`${transitionsRenderKey}-${transitionIndex}`"
    >
      <div class="portfolio-detail__logo-img-container re-animation">
        <img class="logo" :src="baseUrl + sortedcompany[currentActiveIndex].field_navigation_logo" />
      </div>

      <div class="portfolio-detail__ceo-section">
        <div class="portfolio-detail__ceo-img re-animation">
          <img class="ceo-image" :src="baseUrl + sortedcompany[currentActiveIndex].field_ceo_image" />
        </div>
        <div class="portfolio-detail__ceo-details re-animation">
          <p class="name">{{ sortedcompany[currentActiveIndex].field_ceo_name }}</p>
          <p class="designation" v-html="sortedcompany[currentActiveIndex].field_ceo_designation"></p>
          <a
            :href="sortedcompany[currentActiveIndex].field_ceo_linkedin_link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img class="ceo-social" src="@/assets/img/Linkedin_footer.svg" />
          </a>
        </div>
      </div>
      <div class="portfolio-detail__desc-text re-animation">
        <p v-html="portfolioDetailInfo"></p>
      </div>
      <div class="portfolio-detail__icon-block re-animation">
        <a
          :href="sortedcompany[currentActiveIndex].field_open_link"
          target="_blank"
          rel="noopener noreferrer"
          v-if="sortedcompany[currentActiveIndex].field_open_link.length != 0"
        >
          <img class="icon" src="@/assets/img/web-link.svg" />
        </a>
        <a
          :href="sortedcompany[currentActiveIndex].field_facebook_link"
          target="_blank"
          rel="noopener noreferrer"
          v-if="sortedcompany[currentActiveIndex].field_facebook_link.length != 0"
        >
          <img class="icon" src="@/assets/img/Facebook_footer.svg" />
        </a>
        <a
          :href="sortedcompany[currentActiveIndex].field_twitter_link"
          target="_blank"
          rel="noopener noreferrer"
          v-if="sortedcompany[currentActiveIndex].field_twitter_link.length != 0"
        >
          <img class="icon" src="@/assets/img/Twitter_footer.svg" />
        </a>
        <a
          :href="sortedcompany[currentActiveIndex].field_insta_link"
          target="_blank"
          rel="noopener noreferrer"
          v-if="sortedcompany[currentActiveIndex].field_insta_link.length != 0"
        >
          <img class="icon" src="@/assets/img/Instagram_footer.svg" />
        </a>
        <a
          :href="sortedcompany[currentActiveIndex].field_youtube_link"
          target="_blank"
          rel="noopener noreferrer"
          v-if="sortedcompany[currentActiveIndex].field_youtube_link.length != 0"
        >
          <img class="icon" src="@/assets/img/Youtube_footer.svg" />
        </a>
        <a
          :href="sortedcompany[currentActiveIndex].field_company_linkedin_link"
          target="_blank"
          rel="noopener noreferrer"
          v-if="sortedcompany[currentActiveIndex].field_company_linkedin_link != 0"
        >
          <img class="icon hide" src="@/assets/img/Linkedin_footer.svg" />
        </a>
      </div>
      <div class="portfolio-detail__change-page">
        <img class="navigation-icon" src="@/assets/img/portfolio-previous-3x.png" @click="previousPortfolio" />
        <a @click="navigateTo('/portfolio')"><p class="all-portfolio">SEE ALL PORTFOLIO</p></a>
        <img class="navigation-icon invert" src="@/assets/img/portfolio-previous-3x.png" @click="nextPortfolio" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { BACKEND_BASE_IMG_PATH } from '@/utils/constants.js'
export default {
  name: 'PortfolioDetail',
  data() {
    return {
      baseUrl: BACKEND_BASE_IMG_PATH,
      currentActiveIndex: 0,
      transitionRenderKey: 0,
      transitionsRenderKey: 1,
      selectedIndex: this.$route.params.currentIndex
    }
  },
  computed: {
    ...mapState('home', ['company']),
    portfolioDetailInfo() {
      return this.sortedcompany[this.currentActiveIndex].field_company_description
    },
    sortedcompany() {
      const commerceData = this.company.filter(item => item.field_category === 'COMMERCE')
      const mediaData = this.company.filter(item => item.field_category === 'MEDIA &amp; COMMUNITY')
      const solutionData = this.company.filter(item => item.field_category === 'SOLUTION &amp; ENTERTAINMENT')
      const productData = this.company.filter(item => item.field_category === 'PRODUCT')
      let sortedCompnayArray = [...commerceData, ...mediaData, ...solutionData, ...productData]
      let currentTitleIndex = sortedCompnayArray
        .map(company => {
          return company.title
        })
        .indexOf(this.selectedIndex)
      if (this.selectedIndex !== undefined) this.setCurrentIndex(currentTitleIndex)
      return sortedCompnayArray
    }
  },
  mounted() {
    this.getCompany()
    this.currentActiveIndex = JSON.parse(sessionStorage.currentActiveIndex)
  },
  methods: {
    ...mapActions('home', ['getCompany']),
    setCurrentIndex(i) {
      sessionStorage.currentActiveIndex = i
    },
    nextPortfolio() {
      window.scrollTo(0, 0)
      this.transitionRenderKey = this.transitionRenderKey + 1
      this.transitionsRenderKey = this.transitionsRenderKey + 1
      if (this.company.length - 1 == this.currentActiveIndex) {
        this.currentActiveIndex = 0
      } else {
        this.currentActiveIndex++
      }
      sessionStorage.currentActiveIndex = this.currentActiveIndex
    },
    previousPortfolio() {
      window.scrollTo(0, 0)
      this.transitionRenderKey = this.transitionRenderKey + 1
      this.transitionsRenderKey = this.transitionsRenderKey + 1
      if (this.currentActiveIndex == 0) {
        this.currentActiveIndex = this.company.length - 1
      } else {
        this.currentActiveIndex--
      }
      sessionStorage.currentActiveIndex = this.currentActiveIndex
    },
    navigateTo(path) {
      this.$router.push(path, () => {})
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/colors';
@import '@/assets/scss/fonts';
@import '@/assets/scss/media-query-mixins';
@keyframes onClickAimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
html {
  overflow: scroll;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
@mixin whiteLinearGradient {
  width: 100%;
  object-fit: contain;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(
    to left,
    rgba(248, 249, 250, 3) 0%,
    rgba(248, 249, 250, 0.7) 21%,
    rgba(248, 249, 250, 0) 45%
  );
  position: absolute;
}
.re-animation {
  animation: onClickAimation 0.5s;
  animation-timing-function: ease-in;
}
p {
  margin: 0;
  padding: 0;
}
.ceo-image {
  max-height: 90px;
  max-width: 90px;
  border-radius: 50%;
  @include ipad-10-portrait {
    margin-right: 10px;
  }
}
.invert {
  cursor: pointer;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.portfolio-detail {
  display: flex;
  @include ipad-10-portrait {
    flex-direction: column-reverse;
    margin: 0 !important;
  }
  @include ipad-default-portrait {
    flex-direction: column-reverse;
    margin: 0 !important;
  }
  @include iphone-portrait {
    flex-direction: column-reverse;
    margin: 0 16px;
  }
  &__img-container {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    width: 55%;
    overflow: hidden;
    margin-top: 0vh;
    min-height: calc(73vh);
    @include larger-screens {
      min-height: calc(80vh);
    }
    @include ipad-portrait {
      width: 55%;
      min-height: 46vh !important;
    }
    @include ipad-landscape {
      width: 58% !important;
      background-size: 110% 100% !important;
    }
    @include ipad-10-landscape {
      width: 55%;
    }
    @include ipad-10-portrait {
      background-size: 100% 95% !important;
      position: absolute;
      width: 50%;
      overflow: hidden;
      min-height: 46vh !important;
      background-position: center;
      margin: 30px 0 50px 0 !important;
    }
    @include ipad-default-portrait {
      background-size: 100% 95% !important;
      position: relative;
      width: 100%;
      overflow: hidden;
      min-height: 46vh !important;
      background-position: center;
      margin: 30px 0 50px 0 !important;
    }
    @include iphone-portrait {
      background-size: 100% 120%;
      min-height: 34vh;
      position: relative;
      overflow: hidden;
      background-position: center;
      width: 100vw;
      margin: 40px 0 0 -16px;
    }
    .gradient {
      @include computers {
        @include whiteLinearGradient;
      }
      @include larger-screens {
        @include whiteLinearGradient;
      }
      @include ipad-portrait {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
      @include ipad-landscape {
        @include whiteLinearGradient;
      }
      @include ipad-10-landscape {
        width: 100%;
        object-fit: contain;
        height: 100%;
        top: 0;
        left: 0;
        background-image: linear-gradient(
          to left,
          rgba(248, 249, 250, 3) 0%,
          rgba(248, 249, 250, 0.7) 21%,
          rgba(248, 249, 250, 0) 45%
        );
        position: absolute;
      }
    }
    .company-img {
      width: 100%;
      height: 100%;
      @include ipad-10-landscape {
        height: 100%;
      }
    }
  }
  &__change-page {
    display: flex;
    margin: 70px 0 100px 80px;
    @include ipad-10-portrait {
      display: none !important;
    }
    @include ipad-default-portrait {
      display: none !important;
    }
    @include iphone-portrait {
      display: none !important;
    }
    @include ipad-10-landscape {
      margin: 40px 0 120px 80px;
    }
    @include ipad-landscape {
      margin: 40px 0 40px 40px;
    }
    .navigation-icon {
      width: 32px;
      height: 25px;
      cursor: pointer;
    }
    .all-portfolio {
      cursor: pointer;
      padding-top: 2px;
      object-fit: contain;
      height: 25px;
      font-family: GothamMedium;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.13;
      letter-spacing: normal;
      text-align: left;
      color: $dark-blue-color;
    }
  }
  &__change-page-ipad {
    display: none;
    @include ipad-10-portrait {
      margin: 30px auto 55px auto !important;
      display: flex;
      align-self: center;
    }
    @include ipad-default-portrait {
      margin: -10px auto 30px auto !important;
      display: flex;
      align-self: center;
    }
    @include iphone-portrait {
      margin: 30px auto 25px auto;
      display: flex;
      align-self: center;
    }
    .navigation-icon {
      width: 32px;
      height: 25px;
      cursor: pointer;
    }
    .all-portfolio {
      cursor: pointer;
      padding-top: 2px;
      object-fit: contain;
      height: 25px;
      font-family: GothamMedium;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.13;
      letter-spacing: normal;
      text-align: left;
      color: $dark-blue-color;
    }
  }
  &__desc {
    @include computers {
      width: 45%;
    }
    @include larger-screens {
      width: 45%;
    }
    @include ipad-10-landscape {
      width: 50%;
    }
    @include ipad-landscape {
      width: 55%;
    }
    @media (min-width: 979px) and(max-width:1023px) {
      margin-left: 80px !important;
    }
    @include ipad-portrait {
      width: 90% !important;
      margin: 30px auto 0 36px;
    }
    margin: 0 0 0 10px;
    @include ipad-10-portrait {
      width: 87% !important;
      margin: 30px auto 0 51px !important;
      text-align: center;
    }
    @include ipad-default-portrait {
      width: 90% !important;
      margin: 30px auto 0 51px !important;
      text-align: center;
    }
    @include ipad-10-landscape {
      width: 45%;
      margin: 0;
      text-align: left;
    }
    @include iphone-portrait {
      width: 100%;
      margin: 20px auto 0 auto;
    }
  }
  &__logo-img-container {
    width: 230px;
    margin: 20px 0 0 80px;
    .logo {
      max-height: 70px;
      @include computers {
        height: 70px;
        width: 225px;
      }
    }
    @include ipad-10-portrait {
      margin: 0;
      text-align: left !important;
    }
    @include ipad-landscape {
      margin: 10px 0 0 40px;
    }
    @include ipad-default-portrait {
      margin: 0;
      text-align: left !important;
    }
    @include iphone-portrait {
      margin: -15px auto 0 0;
    }
  }
  &__icon-block {
    width: 230px;
    margin: 30px 0 0 80px;
    text-align: left;
    @include ipad-10-portrait {
      width: 200px !important;
    }
    @include ipad-landscape {
      margin: 30px 0 0 40px;
    }
    @include ipad-default-portrait {
      width: 200px !important;
      margin: 30px 0 0 0;
    }
    @include iphone-portrait {
      width: 200px !important;
      margin: 30px auto 0 0;
    }
    .hide {
      @include ipad-10-landscape {
        display: visible;
      }
      @include iphone-portrait {
        display: none;
      }
      @include ipad-10-portrait {
        display: none;
      }
    }
    .icon {
      width: 20px;
      height: 20px;
      margin: 0 15px 0 0;
      @include ipad-10-portrait {
        margin: 0 15px 0 0;
      }
      @include iphone-portrait {
        width: 25px;
        height: 25px;
      }
    }
  }
  &__ceo-details {
    margin: 0 0 0 15px;
    text-align: left;
    @include ipad-10-portrait {
      margin: auto;
    }
  }
  &__ceo-section {
    margin: 30px 0 0 80px;
    display: flex;
    @include ipad-10-portrait {
      margin: 30px auto 0 auto;
    }
    @include ipad-default-portrait {
      margin: 30px auto 0 0;
    }
    @include ipad-landscape {
      margin: 30px 0 0 40px;
    }
    @include iphone-portrait {
      margin: 30px auto 0 0;
    }
    @include computers {
      margin-right: 80px;
    }
    @include ipad-default-landscape {
      margin-right: 80px;
    }
    @media (min-width: 1025px) and (max-width: 1279px) {
      margin-right: 80px;
    }
    &__ceo-img {
      max-width: 90px;
      max-height: 90px;
    }
    .name {
      width: auto;
      font-family: GothamMedium;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      text-align: left;
      color: $gray-44;
    }
    .designation {
      font-family: GothamBook;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: normal;
      text-align: left;
      color: $gray-44;
    }
    .ceo-social {
      text-align: left;
    }
  }
  &__desc-text {
    p {
      overflow-y: auto;
      text-align: left;
      padding: 0;
      object-fit: contain;
      font-family: GothamBook;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color: $light-gray;
      margin: 50px 0 0 80px;
      @include computers {
        margin-right: 80px;
      }
      @include larger-screens {
        width: 70%;
      }
      @include ipad-default-landscape {
        margin-right: 80px;
      }
      @include iphone-portrait {
        margin: 30px 0 0 0;
      }
      @include ipad-10-landscape {
        text-align: left;
        width: 350px;
      }
      @include ipad-landscape {
        margin: 50px 80px 0 40px;
      }
      @include ipad-10-portrait {
        margin: 30px auto 0 auto;
      }
      @include ipad-default-portrait {
        margin: 30px auto 0 0;
      }
    }
  }
}
@media (min-width: $max-width-ipad-pro) and (max-width: $min-width-laptop-and-desktop) {
  .portfolio-detail__desc {
    width: 55%;
  }
  .gradient {
    @include whiteLinearGradient;
  }
}
@media (min-width: 897px) and (max-width: 1023px) and (orientation: 'portrait') {
  .portfolio-detail__desc {
    margin: 30px 51px 40px 50px !important;
  }
}
@media (min-width: 897px) and (max-width: 1023px) and (orientation: 'landscape') {
  .portfolio-detail__desc {
    margin: 30px 51px 40px 80px !important;
  }
}
@media (min-width: 897px) and (max-width: 1023px) {
  .portfolio-detail {
    flex-direction: column-reverse;
  }
  .gradient {
    display: none;
  }
  .portfolio-detail__desc {
    width: 90% !important;
    margin: 30px 51px 40px 50px;
  }
  .portfolio-detail__img-container {
    width: 100% !important;
  }
  .portfolio-detail__logo-img-container {
    margin-left: 0;
  }
  .portfolio-detail__ceo-section {
    margin-left: 0;
  }
  .portfolio-detail__icon-block {
    margin-left: 0;
  }
  .portfolio-detail__desc-text p {
    margin-left: 0;
  }
  .portfolio-detail__change-page-ipad {
    padding-top: 30px;
    display: flex;
    align-self: center;
  }
  .portfolio-detail__change-page {
    display: none;
  }
}
.portfolio-detail__img-container {
  @include computers {
    background-size: cover;
  }
  @include larger-screens {
    background-size: cover;
  }
  @media (min-width: 1025px) and (max-width: 1280px) {
    background-size: cover;
  }
  @include ipad-portrait {
    background-size: cover !important;
  }
  @include ipad-landscape {
    background-size: cover !important;
  }
  @include iphone-portrait {
    background-size: cover;
  }
}
@media (min-width: 1025px) and (max-width: 1279px) {
  .portfolio-detail__desc-text p {
    margin-right: 80px;
  }
}
@include ipad-portrait {
  .gradient {
    display: none;
  }
}
</style>
