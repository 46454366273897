import api from '@/utils/http-api'
import apiConst from '@/utils/api-constants'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    postSubscribeDetails({ name, email, company }) {
      const { postSubscribeDetails } = apiConst.subscribe
      api.postRequest(postSubscribeDetails, {
        name,
        company,
        email
      })
    }
  }
}
