import api from '@/utils/http-api'
import apiConst from '@/utils/api-constants'

export default {
  namespaced: true,
  state: {
    contactDetails: []
  },
  getters: {},
  mutations: {
    setContactDetails(state, payload) {
      state.contactDetails = payload
    }
  },
  actions: {
    getContactDetails({ commit }) {
      const { getContactDetails } = apiConst.contact
      api.getRequest(getContactDetails).then(response => {
        commit('setContactDetails', response.data)
      })
    }
  }
}
