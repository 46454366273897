<template>
  <div class="search-result">
    <div class="search-result__title">
      {{ title }}
    </div>
    <div class="search-result__desc">
      {{ desc }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'SearchResult',
  props: {
    title: {
      type: String,
      required: true
    },
    desc: {
      type: String,
      required: true
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/colors';
@import '@/assets/scss/fonts';
@import '@/assets/scss/media-query-mixins';
@mixin text-style {
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 1;
  margin-bottom: 5px;
}
.search-result {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  @include iphone-portrait {
    margin-bottom: 10px;
  }
  @include ipad-default-portrait {
    margin-bottom: 15px;
  }
  &__title {
    object-fit: contain;
    font-family: GothamBook;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.35px;
    text-align: left;
    color: $light-gray;
    text-transform: uppercase;
    @include ipad-landscape {
      @include text-style;
    }
    @include ipad-10-portrait {
      @include text-style;
    }
    @include iphone-portrait {
      @include text-style;
    }
  }
  &__desc {
    object-fit: contain;
    width: 100%;
    font-family: GothamMedium;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 0.5px;
    text-align: left;
    color: $primary-black;
  }
}
</style>
