<template>
  <div class="app-leader-card">
    <img :src="leaderImage" alt="Avatar" class="leader-image" />
    <div><span class="overlay"></span></div>
    <div><span class="overlay-white"></span></div>
    <div>
      <h1 class="leader-name">{{ leaderName }}</h1>
      <p class="leader-info">{{ leaderInfo }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'leaderCard',
  props: {
    leaderName: {
      type: String,
      required: true
    },
    leaderInfo: {
      type: String,
      required: true
    },
    leaderImage: {
      type: String,
      required: true
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/colors.scss';
@import '@/assets/scss/fonts.scss';
@import '@/assets/scss/media-query-mixins.scss';
.app-leader-card {
  position: relative;
  display: inline-block;
  cursor: pointer;
  .leader-image {
    position: relative;
    display: block;
    width: 318px;
    height: 343px;
    z-index: 10 !important;
    top: 38px;
  }
  .overlay {
    position: absolute;
    top: 40%;
    bottom: 0;
    left: 50%;
    right: 0;
    width: 0;
    height: 0;
    border-radius: 50%;
    opacity: 0;
    background-color: $primary-blue !important;
    z-index: 1;
    display: inline-block;
    transition: 0.3s ease-in-out;
  }
  .overlay-white {
    position: absolute;
    overflow: auto;
    top: 0px;
    bottom: 0;
    left: 0px;
    right: 0;
    width: 296px;
    height: 296px;
    background-color: $primary-white;
    border-radius: 50%;
    z-index: 0;
    display: inline-block;
  }
  .leader-name {
    margin-top: 45px;
    font-size: 20px;
    line-height: 1.38;
    text-align: center;
    color: $primary-black;
    font-weight: 500;
    font-family: GothamMedium;
    line-height: 1.38;
  }
  .leader-info {
    margin-top: -10px;
    font-weight: 500;
    text-align: center;
    font-family: GothamBook;
    line-height: 1.38;
  }
}
.app-leader-card:hover .overlay {
  opacity: 1;
  transition: 0.3s ease-in-out;
  height: 296px;
  width: 296px;
  left: 0;
  top: 0;
}
.app-leader-card:hover .leader-name {
  font-size: 20px;
  color: $dark-blue-color;
}
@mixin leadercard-mixin {
  .leader-image {
    width: 224px;
    height: 242px;
    top: 28px;
  }
  .overlay-white {
    width: 209px;
    height: 209px;
  }
  .leader-name {
    margin-top: 40px;
  }
}
.app-leader-card {
  @include iphone-portrait {
    @include leadercard-mixin;
  }
  @include ipad-portrait {
    @include leadercard-mixin;
  }
  @include iphone-landscape {
    @include leadercard-mixin;
  }
}
///Media Queries for overlay
@mixin leadercard-overlay-mixin {
  .app-leader-card:hover .overlay {
    width: 209px;
    height: 209px;
  }
}
@include iphone-portrait {
  @include leadercard-overlay-mixin;
}
@include iphone-landscape {
  @include leadercard-overlay-mixin;
}
@include ipad-portrait {
  @include leadercard-overlay-mixin;
}
</style>
