<template>
  <div class="app-insight-card">
    <div class="insight-images">
      <img :src="image" alt="none" class="insight-images__image" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'InsightCard',
  props: {
    image: {
      type: String,
      required: true
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/colors.scss';
@import '@/assets/scss/media-query-mixins.scss';
.app-insight-card {
  background-color: $page-background-color;
  display: inline-block;
  &:hover {
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 0 10px;
  }
  .insight-images {
    &__image {
      display: inline-block;
      border-radius: 10px;
      cursor: pointer;
      width: 100% !important;
      height: 300px;
      object-fit: cover;
      @include ipad-10-landscape {
        max-width: 225px;
        max-height: 250px;
      }
      @include ipad-portrait {
        max-height: 225px;
        max-width: 225px;
      }
      @include ipad-landscape {
        max-height: 205px;
        max-width: 205px;
      }
      @include ipad-landscape {
        width: 100% !important;
        max-width: none !important;
      }
      @include ipad-default-portrait {
        max-width: none !important;
      }
    }
  }
}
.app-insight-card {
  @include iphone-portrait {
    &:hover {
      box-shadow: none;
    }
    .insight-images {
      &__image {
        height: 200px;
        width: 200px;
      }
    }
  }
  @include ipad-default-landscape {
    .insight-images {
      &__image {
        max-height: 275px;
      }
    }
  }
  @include ipad-default-portrait {
    .insight-images {
      &__image {
        max-height: 275px;
        width: 100% !important;
      }
    }
  }
  @include iphone-landscape {
    .insight-images {
      &__image {
        max-height: 200px;
        max-width: 200px;
      }
    }
  }
  @include ipad-10-portrait {
    .insight-images {
      &__image {
        height: 225px !important;
        width: 225px;
      }
    }
  }
  @include ipad-10-landscape {
    .insight-images {
      &__image {
        height: 225px;
        max-width: 225px;
      }
    }
  }
  @include computers {
    .insight-images__image {
      height: 307px;
      width: 307px;
    }
  }
  @include larger-screens {
    .insight-images__image {
      height: 350px;
      width: 350px;
    }
  }
}
</style>
