import api from '@/utils/http-api'
import apiConst from '@/utils/api-constants'
export default {
  namespaced: true,
  state: {
    newsCardData: [],
    articleData: [],
    totalPages: 0
  },
  getters: {},
  mutations: {
    setNewsCardData(state, payload) {
      state.newsCardData = [...state.newsCardData, ...payload]
    },
    clearNewsCardData(state, payload) {
      state.newsCardData = payload
    },
    setArticle(state, payload) {
      state.articleData = payload
    },
    setTotalPages(state, payload) {
      state.totalPages = payload
    }
  },
  actions: {
    getNewsCardData({ commit }, payload) {
      const { getNewsCardData } = apiConst.news
      api.postRequest(getNewsCardData, payload).then(response => {
        commit('setNewsCardData', response.data.DATA)
        commit('setTotalPages', response.data.TOTAL_PAGES)
      })
    },
    getArticle({ commit }) {
      const { getArticle } = apiConst.news
      api.getRequest(getArticle).then(response => {
        commit('setArticle', response.data)
      })
    },
    changeNewsCardData({ commit }) {
      commit('clearNewsCardData', [])
    }
  }
}
