<template>
  <footer class="footer ">
    <div class="footer__container">
      <div class="row footer__row-1">
        <div class="col-sm-8">
          <p class="footer__title">Empowering Consumer Culture</p>
        </div>
        <div v-if="footerLinksLength" class="col-sm-4 footer__icon-block">
          <a :href="footerLinks[0].field_follow_fb" target="_blank" rel="noopener noreferrer">
            <img class="icon" src="@/assets/img/Facebook_footer.svg" />
          </a>
          <a :href="footerLinks[0].field_follow_twitter" target="_blank" rel="noopener noreferrer">
            <img class="icon" src="@/assets/img/Twitter_footer.svg" />
          </a>
          <a :href="footerLinks[0].field_follow_instagram" target="_blank" rel="noopener noreferrer">
            <img class="icon" src="@/assets/img/Instagram_footer.svg" />
          </a>
          <a :href="footerLinks[0].field_follow_youtube" target="_blank" rel="noopener noreferrer">
            <img class="icon" src="@/assets/img/Youtube_footer.svg" />
          </a>
          <a :href="footerLinks[0].field_follow_linkedin" target="_blank" rel="noopener noreferrer">
            <img class="icon" src="@/assets/img/Linkedin_footer.svg" />
          </a>
        </div>
      </div>
      <hr class="footer__break-line" />
      <div class="row">
        <ul class="footer__navbar">
          <li class="navbar-item "><a @click="navigateTo('/about')">ABOUT</a></li>
          <li class="navbar-item "><a @click="navigateTo('/portfolio')">PORTFOLIO</a></li>
          <li class="navbar-item "><a @click="navigateTo('/news')">NEWS</a></li>
          <li class="navbar-item "><a @click="navigateTo('/insight')">INSIGHT</a></li>
          <li class="navbar-item "><a @click="navigateTo('/jobs')">JOBS</a></li>
          <li class="navbar-item "><a @click="navigateTo('/legal')">LEGAL</a></li>
          <li class="navbar-item "><a @click="navigateTo('/contact')">CONTACT</a></li>
        </ul>
      </div>
      <div class="row">
        <p class="footer__line">© GDP Venture All Rights Reserved.</p>
      </div>
    </div>
  </footer>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'Footer',
  computed: {
    ...mapState('footer', ['footerLinks']),
    footerLinksLength() {
      return !!this.footerLinks.length
    }
  },
  mounted() {
    this.getFooterLinks()
  },
  methods: {
    ...mapActions('footer', ['getFooterLinks']),
    navigateTo(path) {
      this.$router.push(path, () => {})
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/colors';
@import '@/assets/scss/fonts';
@import '@/assets/scss/media-query-mixins';
.row {
  margin: 0 80px;
  @include iphone-portrait {
    margin: 0 0 0 15px;
  }
  @include ipad-10-portrait {
    margin: 0 0 0 50px;
  }
  @include ipad-landscape {
    margin: 0 0 0 79px;
  }
  @include ipad-portrait {
    margin: 0 50px 0 50px !important;
  }
  @include ipad-default-portrait {
    margin: 0 50px 0 50px !important;
  }
}
.col-sm-8 {
  padding: 0;
  @include ipad-landscape {
    width: 60%;
  }
}
.col-sm-4 {
  padding-left: 0;
  padding-right: 0;
  margin: 0;
  text-align: right;
  @include iphone-portrait {
    margin: 0;
    padding: 0;
  }
}
.footer {
  background-color: $footer-background-color;
  bottom: 0;
  margin-bottom: 0;
  width: 100%;
  &__title {
    margin: 50px 0 0 0;
    font-family: GothamMedium;
    font-size: 28px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: left;
    color: $secondary-black;
    @include ipad-10-portrait {
      font-size: 28px !important;
      width: 100% !important;
      margin: 50px 0 0 0 !important;
    }
    @include ipad-landscape {
      margin: 50px 0px 0px 0px;
    }
    @include iphone-portrait {
      font-size: 26px;
      line-height: 1.38;
      color: $nero;
      width: 50%;
      margin: 50px 230px 0 0;
    }
    @include ipad-portrait {
      margin: 50px 0 0 0 !important;
      width: 100% !important;
    }
  }
  &__line {
    font-family: GothamBook;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: $gray-44;
    margin: 70px 0 53px 0;
    padding: 0;
    @include iphone-portrait {
      margin: 56px 0 53px 0;
      font-size: 10px;
      color: $gray-44;
      line-height: 2.4;
    }
  }
  &__icon-block {
    @include iphone-portrait {
      width: 70%;
      padding: 0;
    }
    @include ipad-10-portrait {
      margin-right: 250px;
    }
  }
  &__break-line {
    visibility: hidden;
    position: relative;
    margin: 0px;
    @include iphone-portrait {
      visibility: visible;
      margin: 30px 16px 0 16px;
      border: solid 1px $gray-44;
    }
    @include ipad-landscape {
      visibility: hidden;
    }
    @include ipad-10-portrait {
      margin: 30px 50px 0 50px;
    }
    @include ipad-portrait {
      margin: 30px 50px 0 50px;
    }
  }
  &__navbar {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
    @include iphone-portrait {
      flex-direction: column;
    }
    @include ipad-landscape {
      flex-direction: row;
    }
    @include ipad-10-portrait {
      flex-direction: row;
    }
    @include ipad-portrait {
      flex-direction: row;
    }
    .navbar-item {
      cursor: pointer;
      list-style: none;
      font-family: GothamMedium;
      font-size: 14px;
      padding: 0px;
      margin: 30px 50px 0 0;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: normal;
      text-align: left;
      color: $gray-44;
      width: max-content;
      @include iphone-portrait {
        margin: 26px 0 0 0;
      }
      @include ipad-landscape {
        margin: 35px 50px 0 0;
      }
      @include ipad-10-portrait {
        margin: 35px 50px 0 0;
      }
      @include ipad-portrait {
        margin: 35px 40px 0 0;
      }
    }
  }
}
.icon {
  height: 21px;
  width: 21px;
  margin: 50px 0px 0px 19px;
  @include iphone-portrait {
    margin: 30px 19px 0 0;
    padding: 0px;
    float: left;
  }
  @include ipad-10-portrait {
    float: left;
    margin: 30px 19px 0 0;
    padding: 0px;
  }
}
</style>
