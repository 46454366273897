import api from '@/utils/http-api'
import apiConst from '@/utils/api-constants'

export default {
  namespaced: true,
  state: {
    detail: [],
    leader: [],
    corporateVideo: [],
    belief: []
  },
  getters: {},
  mutations: {
    setLeader(state, payload) {
      state.leader = payload
    },
    setCorporateVideo(state, payload) {
      state.corporateVideo = payload
    },
    setBeliefCardDetails(state, payload) {
      state.belief = payload
    }
  },
  actions: {
    getLeader({ commit }) {
      const { getLeader } = apiConst.home
      api.getRequest(getLeader).then(response => {
        commit('setLeader', response.data)
      })
    },
    getCorporateVideo({ commit }) {
      const { getCorporateVideo } = apiConst.about
      api.getRequest(getCorporateVideo).then(response => {
        commit('setCorporateVideo', response.data)
      })
    },
    getBeliefCardDetails({ commit }) {
      const { getBeliefCardDetails } = apiConst.about
      api.getRequest(getBeliefCardDetails).then(response => {
        commit('setBeliefCardDetails', response.data)
      })
    }
  }
}
