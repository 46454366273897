<template>
  <div class="subscribe-container">
    <div v-if="showSusbscribeBar && !subscribeStatus" class="subscribe-bar" :style="{ position: subscribeBarPosition }">
      <div class="subscribe-bar__info">
        <img class="subscribe-bar__email-icon" alt="email-icon" :src="require('@/assets/img/email-icon-grey.svg')" />
        <span class="subscribe-bar__text">Get personalized data, insights & inspiration from GDP Venture</span>
      </div>
      <div class="subscribe-bar__buttons">
        <span class="subscribe-bar__subscribe-btn" @click="displaySubscribePopup">SUBSCRIBE</span>
        <span @click="noThanks" class="subscribe-bar__close-link">NO, THANK'S</span>
      </div>
    </div>
    <transition name="fade">
      <SubscribePopup
        v-if="showSubscribe"
        :showSubscribe="showSubscribe"
        :closeSubscribePopup="closeSubscribePopup"
        :closeSubscribeBar="closeSubscribeBar"
      />
    </transition>
  </div>
</template>
<script>
import SubscribePopup from '@/components/SubscribePopup'
export default {
  name: 'SubscribeBar',
  components: {
    SubscribePopup
  },
  data() {
    return {
      showSubscribe: false,
      subscribeBarPosition: 'fixed',
      subscribeStatus: false
    }
  },
  methods: {
    displaySubscribePopup() {
      this.showSubscribe = true
      this.subscribeBarPosition = 'static'
      document.body.style.overflow = 'hidden'
    },
    closeSubscribePopup() {
      this.showSubscribe = false
      this.subscribeBarPosition = 'fixed'
      document.body.style.overflow = 'auto'
    },
    noThanks() {
      sessionStorage.subscribeStatus = true
      this.closeSubscribeBar()
    }
  },
  props: {
    showSusbscribeBar: {
      type: Boolean,
      required: true,
      default: false
    },
    closeSubscribeBar: {
      type: Function,
      required: true
    }
  },
  watch: {
    name() {
      if (this.name.length && this.nameErrorFlag) this.nameErrorFlag = false
    },
    company() {
      if (this.company.length && this.companyErrorFlag) this.companyErrorFlag = false
    },
    email() {
      if (this.email.length && this.emailErrorFlag) this.emailErrorFlag = false
    }
  },
  mounted() {
    this.subscribeStatus = JSON.parse(sessionStorage.subscribeStatus)
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/colors';
@import '@/assets/scss/fonts';
@import '@/assets/scss/media-query-mixins';
@import '@/assets/scss/placeholder-mixins';
.subscribe-container {
  display: none;
  .subscribe-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    color: $primary-white;
    margin: 0;
    background-color: $primary-white;
    z-index: 1000;
    max-height: 70px;
    @include ipad-portrait {
      flex-direction: column;
      justify-content: center;
      max-height: 117px !important;
    }
    @include ipad-default-portrait {
      justify-content: center;
      flex-direction: column;
      max-height: 130px !important;
    }
    @include iphone-portrait {
      flex-direction: column;
      max-height: 119px;
    }
    @include ipad-10-portrait {
      justify-content: center;
      max-height: 117px;
    }
    @include iphone-landscape {
      flex-direction: column;
      max-height: 119px;
    }
    @media (min-width: 896px) and(max-width:926px) {
      flex-direction: column;
      max-height: 119px;
    }
    &__info {
      margin-right: auto;
      float: left;
      display: flex;
      align-items: center;
      margin-left: 80px;
      @include iphone-portrait {
        margin: 13px 48px 10px 16px;
      }
      @include iphone-landscape {
        margin: 13px auto 10px 16px;
      }
      @include ipad-portrait {
        margin: 0 48px 0 50px !important;
      }
      @include ipad-10-portrait {
        margin: 0 auto 0 50px;
      }
      @include ipad-default-portrait {
        margin: 0 auto 0 50px;
      }
      @media (min-width: 896px) and(max-width:926px) {
        margin: 10px 0 0 45px;
      }
    }
    &__email-icon {
      width: 36px;
      height: 22px;
      @include ipad-landscape {
        margin: 0;
      }
      @include iphone-portrait {
        display: none;
      }
      @include iphone-landscape {
        display: none;
      }
      @include ipad-portrait {
        display: initial;
        margin: 25px 11px 23px 0;
      }
      @include ipad-10-portrait {
        display: initial;
        margin: 25px 11px 23px 0;
      }
      @include ipad-default-portrait {
        display: initial;
        margin: 25px 11px 0 0;
      }
    }
    &__text {
      color: $gray-44;
      margin-left: 15px;
      @include ipad-10-portrait {
        width: 100%;
        display: inline !important;
      }
      @include ipad-default-portrait {
        display: initial;
        margin: 25px 11px 0 0;
      }
      @include iphone-portrait {
        display: block;
        text-align: left;
        margin: 0;
        padding: 0;
      }
      @include iphone-landscape {
        display: block;
        text-align: left;
        margin: 0;
        padding: 0;
      }
      @include ipad-landscape {
        font-size: 14px;
      }
      @media (min-width: 896px) and(max-width:926px) {
        margin: 0;
      }
    }
    &__buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 80px;
      @include ipad-portrait {
        justify-content: flex-start;
        margin: 0 auto 10px 50px !important;
      }
      @include ipad-10-portrait {
        margin: 0 auto 20px 50px !important;
      }
      @include iphone-portrait {
        align-items: baseline;
        justify-content: initial;
        margin: 0 auto 10px 0;
      }
      @include ipad-default-portrait {
        margin: 0 auto 20px 50px !important;
      }
      @include iphone-landscape {
        align-items: baseline;
        justify-content: initial;
        margin: 0 auto 10px 0;
      }
      @media (min-width: 896px) and(max-width:926px) {
        justify-content: normal;
      }
    }
    &__subscribe-btn {
      background-color: $primary-blue;
      font-size: 16px;
      line-height: 16px;
      font-weight: 500;
      font-family: GothamMedium;
      padding: 11px 25px 11px 25px;
      border-radius: 35px;
      margin: 16px 0;
      cursor: pointer;
      @include ipad-landscape {
        font-size: 14px;
        padding: 10px 20px 10px 20px;
        margin: 25px 18px 23px 45px;
      }
      @include ipad-portrait {
        margin: 0 15px 0 0 !important;
      }
      @include iphone-portrait {
        margin: 0 15px 0 16px;
      }
      @include iphone-landscape {
        margin: 0 15px 0 16px;
      }
      @include ipad-default-portrait {
        margin: 15px -15px 20px 0;
      }
      @include ipad-10-portrait {
        margin-left: 0;
        margin-top: 0;
      }
      @media (min-width: 896px) and(max-width:926px) {
        margin: 10px 18px 10px 45px;
      }
    }
    &__close-link {
      color: $primary-blue;
      font-size: 16px;
      font-weight: 500;
      font-family: GothamMedium;
      margin: 27px 0 27px 30px;
      cursor: pointer;
      @include ipad-landscape {
        font-size: 14px;
        margin-left: 0;
      }
      @include iphone-portrait {
        margin: 0;
      }
      @include ipad-landscape {
        margin: 0;
      }
      @include iphone-landscape {
        margin: 0;
      }
      @media (min-width: 896px) and(max-width:1023px) {
        margin: 0;
      }
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 2s;
  }
  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }
}
</style>
