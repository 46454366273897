<template>
  <div class="share-container">
    <!-- SHARE BAR -->
    <div class="share-bar">
      <div class="share-bar__info">
        <span class="share-bar__text">{{ title }}</span>
      </div>
      <div class="share-bar__back">
        <img class="share-back" src="@/assets/img/arrowRoundBack.svg" @click="$router.go(-1)" />
        <div class="share-bar__buttons">
          <span class="share-bar__close-link" @click="displaySharePopup">SHARE</span>
          <span class="share-bar__subscribe-btn" @click="displaySubscribePopup">SUBSCRIBE</span>
        </div>
      </div>
    </div>
    <!-- SHARE POPUP -->
    <transition name="fade">
      <div v-if="showSharePopup" id="elementToFade" class="share-popup">
        <div class="share-popup__close-btn">
          <CloseButton :clickOnClose="closeSharePopup" />
        </div>
        <p class="share-popup__heading">Share this page</p>
        <ul class="share-popup__icons-list">
          <li class="share-popup__item">
            <a :href="whatsappLink">
              <img
                class="share-popup__icon"
                alt="whatsapp-icon"
                :src="require('@/assets/img/whatsapp-icon-black.svg')"
              />
            </a>
          </li>
          <li class="share-popup__item">
            <a :href="mail" target="_blank">
              <img class="share-popup__icon" alt="gmail-icon" :src="require('@/assets/img/gmail-icon-black.svg')" />
            </a>
          </li>
          <li class="share-popup__item">
            <a
              target="_blank"
              :href="LinkedinLink"
              onclick="window.open(this.href, 'mywin', 'left=20,top=20,width=500,height=500,toolbar=1,resizable=0'); return false;"
            >
              <img
                class="share-popup__icon"
                alt="linkedin-icon"
                :src="require('@/assets/img/linkedin-icon-black.svg')"
              />
            </a>
          </li>
          <li class="share-popup__item">
            <a target="_blank" class="twitter-share-button" :href="twitterLink">
              <img class="share-popup__icon" alt="twitter-icon" :src="require('@/assets/img/twitter-icon-black.svg')" />
            </a>
          </li>
          <li class="share-popup__item">
            <a :href="facebookLink" target="_blank">
              <img class="share-popup__icon" alt="facebook-icon" :src="require('@/assets/img/facebook-black.svg')" />
            </a>
          </li>
        </ul>
        <hr class="share-popup__horizontal-line" />
        <div class="share-popup__content">{{ title }}</div>
      </div>
    </transition>
    <!-- SUBSCRIBE POPUP -->
    <transition name="fade">
      <SubscribePopup
        v-if="showSubscribe"
        :showSubscribe="showSubscribe"
        :closeSubscribePopup="closeSubscribePopup"
        :closeSubscribeBar="closeSubscribeBar"
      />
    </transition>
    <!-- FOR MASK -->
    <div v-if="showSharePopup" @click="removeMask" class="mask"></div>
  </div>
</template>
<script>
import CloseButton from '@/components/CloseButton'
import SubscribePopup from '@/components/SubscribePopup'
export default {
  name: 'ShareBar',
  data() {
    return {
      showShareBar: false,
      showSharePopup: false,
      showSubscribe: false,
      twitterLink: 'https://twitter.com/intent/tweet?text=&url=' + this.newsID,
      facebookLink: 'https://www.facebook.com/sharer/sharer.php?u=' + this.newsID,
      mail: 'mailto:someone@example.com?Subject=Hello%20again&body=' + this.newsID,
      LinkedinLink: 'https://www.linkedin.com/shareArticle?mini=true&url=' + this.newsID,
      whatsappLink: 'whatsapp://send?text=' + this.newsID
    }
  },
  components: {
    CloseButton,
    SubscribePopup
  },
  methods: {
    displaySharePopup() {
      this.showSharePopup = true
    },
    closeSharePopup() {
      this.showSharePopup = false
    },
    removeMask() {
      this.showSharePopup = false
    },
    displaySubscribePopup() {
      this.showSubscribe = true
    },
    closeSubscribePopup() {
      this.showSubscribe = false
    }
  },
  props: {
    title: {
      type: String,
      required: true
    },
    newsID: {
      type: String,
      required: true
    },
    showSusbscribeBar: {
      type: Boolean,
      required: true,
      default: false
    },
    closeSubscribeBar: {
      type: Function,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/colors';
@import '@/assets/scss/fonts';
@import '@/assets/scss/media-query-mixins';
@import '@/assets/scss/placeholder-mixins';

.share-container {
  // For MASK
  .mask {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $mask-background-color;
  }
  // FOR SHARE-BAR
  .share-bar__back {
    @include iphone-portrait {
      display: flex;
      justify-content: space-between;
    }
    @include ipad-portrait {
      padding: 20px 10px 0 10px;
    }
  }
  .share-back {
    display: none;
    @include iphone-portrait {
      display: block;
      height: 30px;
      width: 30px;
      padding-left: 10px;
      padding-top: 10px;
    }
    @include ipad-10-portrait {
      display: none;
    }
  }
  .share-bar {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    width: 100%;
    color: $primary-white;
    margin: 0;
    background-color: $primary-white;
    @include ipad-portrait {
      flex-direction: row;
    }
    @include ipad-10-portrait {
      flex-direction: row !important;
      justify-content: center;
    }
    @include iphone-portrait {
      flex-direction: column;
    }
    @include iphone-landscape {
      flex-direction: column;
    }
    &__info {
      margin-right: auto;
      float: left;
      display: flex;
      align-items: center;
      margin-left: 80px;
      @include iphone-portrait {
        margin: 13px 48px 10px 16px;
      }
      @include iphone-landscape {
        margin: 13px auto 10px 16px;
      }
      @include ipad-10-portrait {
        margin: 0px auto 0px 50px;
      }
      @include ipad-portrait {
        margin: 0px auto 0px 80px;
      }
    }
    &__text {
      color: $gray-44;
      margin: 0;
      @include ipad-10-portrait {
        display: block !important;
      }
      @include iphone-portrait {
        display: none;
      }
      @include iphone-landscape {
        display: block;
        text-align: left;
        margin: 0;
        padding: 0;
      }
    }
    &__buttons {
      display: flex;
      margin-right: 80px;
      align-items: center;
      @include ipad-portrait {
        justify-content: flex-start;
      }
      @include ipad-10-portrait {
        margin: 20px !important;
      }
      @include iphone-portrait {
        margin: 0;
        justify-content: flex-end;
        margin-bottom: 20px;
      }
      @include iphone-landscape {
        align-items: baseline;
        justify-content: initial;
        margin: 0 auto 10px 15px;
      }
    }
    &__subscribe-btn {
      //can add when enabled subscribe option
      display: none;
      background-color: $primary-blue;
      font-size: 16px;
      line-height: 16px;
      font-weight: 500;
      font-family: GothamMedium;
      padding: 11px 25px 11px 25px;
      border-radius: 35px;
      margin: 16px 0;
      cursor: pointer;
      @include ipad-landscape {
        margin: 25px 18px 23px 0px;
      }
      @include ipad-portrait {
        margin: 0;
      }
      @include iphone-portrait {
        margin: 0 15px 0 16px;
      }
      @include iphone-landscape {
        margin: 0 15px 0 16px;
      }
      @include ipad-10-portrait {
        margin-left: 20px;
        margin-top: 0;
      }
    }
    &__close-link {
      color: $primary-blue;
      font-size: 16px;
      font-weight: 500;
      font-family: GothamMedium;
      margin: 27px 35px 27px 0px;
      cursor: pointer;
      @include iphone-portrait {
        margin: 0;
      }
      @include iphone-landscape {
        margin: 0;
      }
    }
  }
  // FOR SHARE POPUP
  .share-popup {
    position: fixed;
    z-index: 100;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 600px;
    height: 400px;
    color: $primary-black;
    border: 1px solid $whisper-gray;
    border-radius: 10px;
    background-color: $primary-white;
    @include iphone-5se-potrait {
      width: 300px !important;
      height: 350px !important;
    }
    @include ipad-10-portrait {
      width: 600px !important;
      height: 400px !important;
    }
    @include iphone6-7-8-potrait {
      width: 350px !important;
    }
    @include iphone-portrait {
      width: 382px;
      height: 375px;
    }
    & ::v-deep .close-container__btn {
      height: 50px;
      width: 50px;
      font-size: 25px;
      @include iphone-portrait {
        height: 30px;
        width: 30px;
        font-size: 12px;
      }
      @include ipad-10-portrait {
        height: 50px;
        width: 50px;
        font-size: 25px;
      }
    }
    &__close-btn {
      margin: 34px;
      text-align: center;
      cursor: pointer;
    }
    &__heading {
      font-family: GothamMedium;
      text-align: center;
      font-size: 32px;
      font-weight: 500;
      @include iphone-portrait {
        font-size: 24px;
      }
    }
    &__icons-list {
      display: flex;
      justify-content: center;
      list-style-type: none;
      margin-bottom: 30px;
      @include iphone-portrait {
        margin-left: auto;
        margin-right: auto;
      }
      .share-popup__item {
        margin-right: 33px;
        margin-bottom: 0;
        @include iphone-portrait {
          margin-right: 29px;
          padding: 0;
        }
        .share-popup__icon {
          height: 40px;
          width: 40px;
          cursor: pointer;
          @include iphone-portrait {
            height: 35px;
            width: 35px;
          }
        }
      }
    }
    &__horizontal-line {
      width: 90%;
      margin-left: 5%;
    }
    &__content {
      margin: 30px 25px 75px 26px;
      font-family: GothamMedium;
      font-weight: 500;
      font-size: 24px;
      text-align: center;
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 2s;
  }
  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }
}
</style>
