<template>
  <div class="belief-card">
    <div class="belief-card__img-container">
      <img class="belief-card__img" :src="imgUrl" />
    </div>
    <div class="belief-card__text">
      <p class="belief-card__heading">{{ heading }}</p>
      <p class="belief-card__desc">{{ desc }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BeliefCard',
  props: {
    heading: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    imgUrl: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/colors';
@import '@/assets/scss/fonts';
@import '@/assets/scss/media-query-mixins';
p {
  margin: 0;
  padding: 0;
}
.belief-card {
  max-width: 290px;
  @include ipad-10-portrait {
    max-width: 315 !important;
    max-height: 450 !important;
  }
  @include iphone-portrait {
    margin: auto;
    text-align: center;
    max-width: 318px;
    margin-bottom: 40px !important;
  }
  @include ipad-landscape {
    max-width: 265px;
  }
  @include ipad-default-portrait {
    margin-bottom: 40px !important;
  }
  @include computers {
    max-width: 330px;
  }
  @include larger-screens {
    max-width: 350px;
  }
  &__img-container {
    @include ipad-10-portrait {
      height: 290px;
    }
  }
  &__img {
    border-radius: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    @include ipad-10-portrait {
      width: 100% !important;
      height: 100% !important;
    }
    @include iphone-portrait {
      width: 65%;
      height: 65%;
    }
  }
  &__text {
    margin: 30px 0 0 0;
    @include iphone-portrait {
      margin: 5px 0 0 0;
    }
    @include ipad-portrait {
      margin-top: 5px;
    }
    @include ipad-default-portrait {
      margin-top: 10px;
    }
  }
  &__heading {
    object-fit: contain;
    font-family: GothamMedium;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: center;
    color: $primary-black;
    @include iphone-portrait {
      font-size: 22px;
      font-weight: 500;
      line-height: 1.36;
      letter-spacing: 1.1px;
    }
  }
  &__desc {
    object-fit: contain;
    font-family: GothamBook;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;
    margin-top: 5px;
    color: $light-gray;
    @include ipad-landscape {
      padding: 0 15px;
    }
    @include ipad-10-portrait {
      padding: 0 5px !important;
      margin: 5px 10px 0 10px !important;
    }
    @include iphone-portrait {
      letter-spacing: 0.29px;
      margin: 0 13px 0 13px;
    }
  }
}
</style>
