<template>
  <div class="about-banner">
    <div
      class="about-banner-info"
      :class="[
        aboutBannerTransition ? 'about-banner-up-transition' : '',
        aboutInfoLeftTransiton ? 'about-banner-open-transiton' : ''
      ]"
    >
      <div class="about-banner-keyframes">
        <h1 class="about-banner-info__about">About</h1>
        <div v-html="aboutdescription" style="white-space: pre-line" class="about-banner-info__para"></div>
      </div>
      <div class="about-banner-video-keyframes" @click="openCorporateVideo">
        <img src="@/assets/img/about-corporate-play.png" alt="none" class="about-banner-video" />
      </div>
    </div>
    <div class="about-banner-image" :class="aboutBannerTransition ? 'about-banner-transition' : ''">
      <img :srcset="aboutBannerPrimaryImage" alt="none" class="about-banner-logo1" />
      <img :srcset="aboutBannerSecondaryImage" alt="none" class="about-banner-logo2" />
    </div>
    <div v-if="displayCorporateVideo" class="video_popup">
      <CorporateVideo :closeMenu="closeMenu" :videoUrl="corporateVideoUrl" />
    </div>
  </div>
</template>
<script>
import CorporateVideo from '@/components/CorporateVideo'
export default {
  name: 'AboutBanner',
  props: {
    corporateVideoUrl: {
      type: String,
      default: ''
    },
    aboutDescription: {
      type: String,
      default: ''
    },
    aboutBannerPrimaryImage: {
      type: String,
      default: ''
    },
    aboutBannerSecondaryImage: {
      type: String,
      default: ''
    }
  },
  components: { CorporateVideo },
  data() {
    return {
      displayCorporateVideo: false,
      aboutBannerTransition: false,
      aboutInfoLeftTransiton: false
    }
  },
  computed: {
    aboutdescription() {
      return this.aboutDescription
    }
  },
  methods: {
    openCorporateVideo() {
      this.displayCorporateVideo = true
      document.body.style.overflow = 'hidden'
      this.aboutBannerTransition = false
      this.aboutInfoLeftTransiton = true
    },
    closeMenu() {
      this.displayCorporateVideo = false
      document.body.style.overflow = 'auto'
      this.aboutBannerTransition = true
      this.aboutInfoLeftTransiton = false
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/colors.scss';
@import '@/assets/scss/media-query-mixins.scss';
.about-banner {
  display: flex;
  background-color: $page-background-color;
}
.video_popup {
  position: fixed;
  z-index: 10000;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $mask-background-color;
}
@keyframes closeTransition {
  0% {
    opacity: 1;
    transform: scale(1.8, 2) translateX(-60%);
  }
  100% {
    opacity: 1;
  }
}
@keyframes closeUpTransition {
  0% {
    transform: translate3d(-100vh, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes mymove {
  0% {
    transform: translateY(-200%);
    opacity: 0;
  }
  45% {
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes mymovetop {
  0% {
    transform: translateY(300%);
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes mymoveleft {
  0% {
    transform: translate3d(100vh, 0, 0);
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes about-banner-info-left-transition {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 1;
    transform: translateX(-100%);
  }
}
.about-banner {
  background-color: page-background-color;
  position: relative;
  .about-banner-info {
    width: 45%;
    text-align: left;
    padding-top: 105px;
    padding-left: 80px;
    &__about {
      font-size: 32px;
      font-weight: 500;
      line-height: 1.38;
      color: $heading-black;
      font-family: GothamMedium;
    }
    &__para {
      margin-top: 18px;
      font-family: GothamBook;
      color: $light-gray;
      font-size: 18px;
      line-height: 1.67;
      letter-spacing: 0.29px;
    }
  }
  .about-banner-video {
    width: 269.6px;
    height: 27px;
    float: left;
    cursor: pointer;
  }
  .about-banner-image {
    width: 60%;
    .about-banner-logo1 {
      width: 100%;
      height: 100%;
      object-fit: contain;
      margin-top: -30px;
    }
    .about-banner-logo2 {
      display: none;
    }
  }
}
.about-banner {
  @media (max-width: $max-width-iphone-11-XR-XSmax) and (orientation: 'portrait') {
    flex-direction: column-reverse;
    .about-banner-info {
      width: 100% !important;
      padding-left: 16px;
      padding-top: 0px;
      padding-right: 16px;
      &__about {
        font-size: 24px;
        font-weight: 500px;
        line-height: 1.42;
        margin-top: 20px;
      }
      &__para {
        font-size: 18px;
        font-family: GothamBook;
        color: $light-gray;
        line-height: 1.33;
        letter-spacing: 0.29px;
        margin-bottom: 5px;
      }
    }
    .about-banner-video {
      width: 180px;
      height: 20px;
      cursor: pointer;
      margin-top: -15px;
      margin-bottom: 20px;
    }
    .about-banner-image {
      width: 100% !important;
      .about-banner-logo1 {
        display: none;
      }
      .about-banner-logo2 {
        display: block !important;
        width: 100% !important;
        height: 100%;
      }
    }
  }
  @include iphone-landscape {
    .about-banner-info {
      width: 45%;
      padding-left: 16px;
      padding-top: 10px;
      &__about {
        font-size: 20px;
        font-weight: 500;
        line-height: 1;
      }
      &__para {
        font-family: GothamBook;
        color: $light-gray;
        font-size: 12px;
        margin-bottom: 8px;
        line-height: 1.5;
        letter-spacing: 0.2px;
      }
    }
    .about-banner-video {
      margin-top: 10px;
      width: 166px;
      height: 15px;
      float: left;
      cursor: pointer;
    }
    .about-banner-image {
      width: 55%;
      .about-banner-logo1 {
        width: 100%;
        height: 100%;
      }
      .about-banner-logo2 {
        display: none;
      }
    }
  }
  @include ipad-portrait {
    flex-direction: column-reverse;
    .about-banner-info {
      padding-left: 51px;
      padding-top: 0px;
      width: 100%;
      &__about {
        font-size: 24px;
        font-weight: 500;
        line-height: 1.42;
        margin-top: 30px !important;
        margin-bottom: 15px;
      }
      &__para {
        margin-bottom: 18px;
        width: 70%;
        font-family: GothamBook;
        color: $light-gray;
        font-size: 18px;
        line-height: 1.67;
        letter-spacing: 0.29px;
      }
    }
    .about-banner-video {
      width: 267px;
      height: 27px;
      float: left;
      cursor: pointer;
    }
    .about-banner-image {
      width: 100% !important;
      .about-banner-logo1 {
        display: none;
      }
      .about-banner-logo2 {
        display: block !important;
        width: 100% !important;
        height: 100%;
      }
    }
  }
  @include ipad-10-landscape {
    .about-banner-info {
      width: 45%;
      padding-left: 80px;
      padding-top: 30px;
      &__about {
        font-size: 32px;
        font-weight: 500;
        margin-bottom: 20px;
        line-height: 1.38;
      }
      &__para {
        font-family: GothamBook;
        color: $light-gray;
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
    .about-banner-video {
      width: 166px;
      height: 15px;
      margin-top: 0px !important;
      cursor: pointer;
    }
    .about-banner-image {
      width: 60%;
      .about-banner-logo1 {
        width: 100%;
        height: 100%;
      }
      .about-banner-logo2 {
        display: none;
      }
    }
    .about-banner-keyframes {
      animation: mymove 1.2s;
    }
    .about-banner-open-transiton {
      animation: about-banner-info-left-transition 1.6s;
    }
    .about-banner-video {
      animation: mymovetop 1.2s;
    }
    .about-banner-image {
      animation: mymoveleft 1.2s;
    }
    .about-banner-transition {
      animation: closeTransition 1.8s;
    }
    .about-banner-up-transition {
      animation: closeUpTransition 2s;
    }
  }
}
@media only screen and (min-device-width: 834px) and (max-device-width: 896px) and (orientation: 'landscape') and (-webkit-min-device-pixel-ratio: 2) {
  .about-banner-info {
    &__about {
      margin-left: -60px;
    }
    &__para {
      margin-left: -60px;
    }
  }
  .about-banner-video {
    margin-left: -60px;
  }
  .about-banner-keyframes {
    animation: mymove 1.2s;
  }
  .about-banner-video {
    animation: mymovetop 1.2s;
  }
  .about-banner-image {
    animation: mymoveleft 1.2s;
  }
  .about-banner-transition {
    animation: closeTransition 1.8s;
  }
  .about-banner-up-transition {
    animation: closeUpTransition 2s;
  }
}
@mixin aboutBannerCloseTransition {
  .about-banner-keyframes {
    animation: mymove 1.2s;
  }
  .about-banner-video {
    animation: mymovetop 1.2s;
  }
  .about-banner-image {
    animation: mymoveleft 1.2s;
  }
  .about-banner-transition {
    animation: closeTransition 1.8s;
  }
  .about-banner-up-transition {
    animation: closeUpTransition 2s;
  }
}
@include computers {
  @include aboutBannerCloseTransition;
  .about-banner-logo1 {
    height: 100% !important;
  }
  .about-banner-open-transiton {
    animation: about-banner-info-left-transition 1.6s;
  }
}
@include ipad-landscape {
  .about-banner-image {
    width: 58% !important;
  }
  .about-banner-logo1 {
    height: 100% !important;
  }
  .about-banner-info {
    padding-top: 25px !important;
  }
  .about-banner-video {
    margin-top: -30px !important;
  }
  .about-banner-info__para {
    margin-top: 0;
    line-height: 1.37 !important;
    letter-spacing: 0.1px !important;
    font-size: 16px !important;
  }
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .about-banner-info__para {
    font-size: 14px !important;
  }
  .about-banner-info {
    padding-top: 30px !important;
  }
}
@media (min-width: 1280px) and (max-width: 1400px) {
  .about-banner-info__para {
    font-size: 16px !important;
  }
}
@include larger-screens {
  @include aboutBannerCloseTransition;
  .about-banner-open-transiton {
    animation: about-banner-info-left-transition 1.6s;
  }
  .about-banner-logo1 {
    height: 100% !important;
  }
  .about-banner-image {
    width: 57% !important;
  }
}
</style>
