<template>
  <div>
    <div class="portfolio-card">
      <img class="portfolio-card__image " alt="portfolio-card-image" v-lazy="imgUrl" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'PortfolioCard',
  props: {
    imgUrl: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/colors';
@import '@/assets/scss/media-query-mixins';
.portfolio-card {
  border-radius: 10px;
  border: 1px solid $whisper-gray;
  box-shadow: 1px 1px 1px 1px $whisper-gray;
  background-color: $primary-white;
  display: inline-block;
  cursor: pointer;
  &__image {
    height: 140px;
    width: 249px;
    @include ipad-10-portrait {
      height: 113px;
      width: 201px;
    }
    @include iphone-portrait {
      height: 88px;
      width: 144px;
    }
    @include iphone-landscape {
      height: 88px;
      width: 144px;
    }
    @include ipad-portrait {
      height: 113px;
      width: 201px;
    }
  }
}
</style>
