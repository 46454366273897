<template>
  <div class="home">
    <div class="home-portfolio">
      <div :class="primaryHeader ? 'primaryHeaderStyle' : 'secondaryHeaderStyle'" id="header">
        <Header
          :logoUrl="logoUrl"
          :linkColor="linkColor"
          :searchIcon="searchIcon"
          :horizontalDotsIcon="horizontalDotsIcon"
          :hamburgerIcon="hamburgerIcon"
          :appMenuStatusUpdate="appMenuStatusUpdate"
        />
      </div>
      <div class="home-banner">
        <HomeBanner
          :bannerText="bannerText"
          :bannerButton="bannerButtonText"
          :bannerImage="baseUrl + bannerUrl"
          :scrollDirection="scrollDirection"
        />
      </div>
      <div v-if="checkCompanyDetails" class="carousel">
        <PortfolioCarousel
          :setBannerUrl="setBannerUrl"
          class="home-carousel"
          :setScrollDirection="setScrollDirection"
          :company="this.company"
        />
      </div>
    </div>
    <div class="subscribe-bar">
      <SubscribeBar :showSusbscribeBar="showSusbscribeBar" :closeSubscribeBar="closeSubscribeBar" />
    </div>
    <div class="news">
      <div class="news-header">
        <div class="news-heading">News</div>
        <div class="news-btn" @click="navigateTo('/news')">SEE ALL</div>
      </div>
      <div class="news-cards">
        <div class="row news__row">
          <div
            class="col-lg-3 col-md-3 col-xs-12 news__item"
            v-for="(data, index) in news.slice(0, 4)"
            :key="index"
            @click="goToArticle(data.field_news_id)"
          >
            <NewsCardHome
              :type="data.field_article === 'true' ? 'ARTICLE' : 'COVERAGE'"
              :title="data.title"
              :imgUrl="baseUrl + data.field_news_slide_image"
            />
          </div>
        </div>
        <div class="row news__row">
          <div
            class="col-lg-3 col-md-3 col-xs-12 news__item"
            v-for="(data, index) in news.slice(4, 8)"
            :key="index"
            @click="goToArticle(data.field_news_id)"
          >
            <NewsCardHome
              :type="data.field_article === 'true' ? 'ARTICLE' : 'COVERAGE'"
              :title="data.title"
              :imgUrl="baseUrl + data.field_news_slide_image"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="insight">
      <div class="insight-header">
        <div class="insight-heading">Insight</div>
        <div class="insight-btn" @click="navigateTo('/insight')">SEE ALL</div>
      </div>
      <div class="row insight__row">
        <div
          class="insight__item col-lg-3 col-md-3 col-xs-12"
          v-for="(insight, index) in insights.slice(0, 4)"
          :key="index"
        >
          <a :href="baseUrl + insight.field_attachments" target="_blank">
            <InsightCard :image="baseUrl + insight.field_article_image" />
          </a>
        </div>
      </div>
    </div>
    <div class="home-footer">
      <Footer />
    </div>
  </div>
</template>
<script>
import { BACKEND_BASE_IMG_PATH } from '@/utils/constants.js'
import { mapActions, mapState } from 'vuex'
import { Header, PortfolioCarousel, HomeBanner, SubscribeBar, NewsCardHome, InsightCard, Footer } from '@/components'
export default {
  components: {
    Header,
    SubscribeBar,
    PortfolioCarousel,
    NewsCardHome,
    InsightCard,
    Footer,
    HomeBanner
  },
  data() {
    return {
      showSusbscribeBar: true,
      logoUrl: require('@/assets/img/gdp-logo-white.svg'),
      searchIcon: require('@/assets/img/search-icon-white.svg'),
      horizontalDotsIcon: require('@/assets/img/horizontal-dots-white.svg'),
      hamburgerIcon: require('@/assets/img/hamburger-button-white.svg'),
      linkColor: '#FFFFFF',
      bannerUrl: '',
      bannerText: '',
      bannerButtonText: '',
      scrollDirection: true,
      primaryHeader: true,
      baseUrl: BACKEND_BASE_IMG_PATH
    }
  },
  methods: {
    ...mapActions('home', ['getCompany', 'getInsights', 'getNews']),
    goToArticle(newsID) {
      this.$router.push({ path: 'article', query: { newsID: newsID } })
    },
    closeSubscribeBar() {
      this.showSusbscribeBar = false
    },
    setBannerUrl(url, bannerText, bannerButtonText) {
      this.bannerUrl = url
      this.bannerText = bannerText
      this.bannerButtonText = bannerButtonText
    },
    setScrollDirection(direction) {
      this.scrollDirection = direction
    },
    navigateTo(path) {
      this.$router.push(path, () => {})
    },
    onScroll() {
      if (window.pageYOffset > 100) {
        this.primaryHeader = false
        this.logoUrl = require('@/assets/img/gdp-blue-logo.svg')
        this.searchIcon = require('@/assets/img/search-icon-blue.svg')
        this.horizontalDotsIcon = require('@/assets/img/horizontal-dots-blue.svg')
        this.hamburgerIcon = require('@/assets/img/hamburger-button-blue.svg')
        this.linkColor = '#004B80'
      } else {
        this.primaryHeader = true
        this.logoUrl = require('@/assets/img/gdp-logo-white.svg')
        this.searchIcon = require('@/assets/img/search-icon-white.svg')
        this.horizontalDotsIcon = require('@/assets/img/horizontal-dots-white.svg')
        this.hamburgerIcon = require('@/assets/img/hamburger-button-white.svg')
        this.linkColor = '#FFFFFF'
      }
    },
    appMenuStatusUpdate(updatedStatus) {
      this.showSusbscribeBar = updatedStatus
    },
    delayOtherApiCalls() {
      this.getInsights()
      this.getNews()
    }
  },
  computed: {
    ...mapState('home', ['news', 'insights', 'company']),
    checkCompanyDetails() {
      return !!this.company.length
    }
  },
  mounted() {
    document.addEventListener('scroll', this.onScroll, {
      passive: true
    })
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.onScroll)
  },
  created() {
    this.getCompany(this.delayOtherApiCalls)
    if (!sessionStorage.subscribeStatus) sessionStorage.subscribeStatus = false
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/colors';
@import '@/assets/scss/fonts';
@import '@/assets/scss/media-query-mixins';
.home {
  position: relative;
  &-portfolio {
    position: relative;
    z-index: 0;
    .primaryHeaderStyle {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1;
    }
    .secondaryHeaderStyle {
      position: fixed !important;
      top: 0;
      left: 0;
      width: 100%;
      background: #ffffff;
      width: 100%;
      z-index: -1;
      box-shadow: 0 8px 8px -6px rgba(0, 0, 0, 0.5);
    }
    & ::v-deep .gdp-header {
      box-shadow: none;
    }
    .home-carousel {
      position: absolute;
      bottom: 74px;
      left: 0;
      width: 100%;
      @include ipad-10-portrait {
        bottom: 131px !important;
      }
      @include iphone-portrait {
        bottom: 100px;
      }
      @media (min-width: $min-width-iphoneXS) and (max-width: $max-width-iphone-11-XR-XSmax) and (orientation: 'portrait') {
        margin-bottom: 40px;
      }
      @include ipad-default-portrait {
        bottom: 121px;
      }
      @include ipad-portrait {
        bottom: 121px;
      }
      @media (min-width: 320px) and (max-width: 390px) and (orientation: 'portrait') {
        bottom: 40px;
        margin-bottom: 0;
      }
    }
    .home-banner {
      width: 100%;
      top: 0;
      left: 0;
      height: 100vh !important;
    }
  }
  .news {
    background-color: $page-background-color;
    margin: 50px 80px;
    display: flex;
    flex-direction: column;
    @include ipad-10-portrait {
      margin: 30px 50px !important;
    }
    @include iphone-portrait {
      margin: 30px 16px;
      margin-right: 0;
    }
    @include ipad-portrait {
      margin: 30px 50px !important;
      margin-right: 0 !important;
    }
    &-header {
      display: flex;
      justify-content: flex-end;
      .news-heading {
        margin-right: auto;
        font-size: 24px;
        font-weight: 500;
        font-family: GothamMedium;
        color: $primary-black;
      }
      .news-btn {
        font-size: 14px;
        text-align: right;
        align-self: center;
        color: $primary-blue;
        font-weight: 500;
        font-family: GothamMedium;
        cursor: pointer;
        letter-spacing: 1px;
        @include ipad-portrait {
          margin-right: 50px !important;
        }
        @include iphone-portrait {
          margin-right: 16px;
        }
      }
    }
    .news__row {
      @include ipad-10-portrait {
        flex-flow: row;
      }
      @include ipad-default-portrait {
        flex-flow: row;
      }
      @include ipad-landscape {
        flex-flow: row;
      }
      @include iphone-portrait {
        flex-flow: row;
      }
    }
    .news-cards {
      margin-top: 15px;
      @include iphone-portrait {
        margin-left: -16px;
        overflow-x: auto;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      @include ipad-10-portrait {
        overflow-x: auto;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      @include ipad-default-portrait {
        overflow-x: auto;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      @include ipad-landscape {
        overflow-x: auto;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      @include ipad-portrait {
        margin-left: -50px;
        overflow-x: auto;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
    .news__item {
      margin-bottom: 25px;
      @include ipad-10-portrait {
        width: auto;
        margin-bottom: 25px;
      }
      @include ipad-default-portrait {
        width: auto;
        margin-bottom: 25px;
      }
      @include iphone-portrait {
        width: auto;
      }
      @include ipad-landscape {
        width: 25% !important;
      }
    }
  }
  .insight {
    background-color: $page-background-color;
    margin: 0 80px;
    display: flex;
    flex-direction: column;
    @include ipad-10-portrait {
      margin: 30px 50px !important;
    }
    @include iphone-portrait {
      margin: 30px 16px;
      margin-right: 0;
    }
    @include ipad-portrait {
      margin: 30px 50px !important;
      margin-right: 0 !important;
    }
    @include ipad-landscape {
      margin: 30px 80px !important;
    }
    &-header {
      display: flex;
      justify-content: flex-end;
      .insight-heading {
        margin-right: auto;
        font-size: 24px;
        font-weight: 500;
        font-family: GothamMedium;
        color: $primary-black;
      }
      .insight-btn {
        font-size: 14px;
        text-align: right;
        align-self: center;
        color: $primary-blue;
        font-weight: 500;
        font-family: GothamMedium;
        cursor: pointer;
        letter-spacing: 1px;
        @include ipad-portrait {
          margin-right: 50px !important;
        }
        @include iphone-portrait {
          margin-right: 16px;
        }
      }
    }
    .insight__row {
      margin-top: 15px;
      @include ipad-10-portrait {
        flex-flow: row;
        overflow-x: auto;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      @include ipad-default-portrait {
        flex-flow: row;
        overflow-x: auto;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      @include ipad-landscape {
        flex-flow: row;
        overflow-x: auto;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      @include iphone-portrait {
        margin-left: -16px;
        margin-right: 0;
        flex-flow: row;
        overflow-x: auto;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      @include ipad-portrait {
        margin-left: -50px;
        overflow-x: auto;
      }
    }
    .insight__item {
      margin-bottom: 25px;
      @include ipad-10-portrait {
        width: auto;
      }
      @include iphone-portrait {
        width: 55%;
      }
      @include ipad-default-portrait {
        width: 30%;
        margin-bottom: 25px;
      }
      @include ipad-default-landscape {
        width: 25%;
        margin-bottom: 25px;
        align-self: auto;
      }
      @include ipad-landscape {
        width: 25% !important;
        margin-bottom: 25px;
      }
      @include ipad-portrait {
        width: 30%;
        padding-left: 0;
      }
    }
  }
}
.news__item:first-child {
  @include iphone-portrait {
    margin-left: 16px;
  }
  @include ipad-portrait {
    margin-left: 50px;
  }
}
.insight__item:first-child {
  @include ipad-portrait {
    padding-left: 0;
    margin-left: 50px !important;
  }
}
::v-deep .insight-images__image {
  aspect-ratio: 1/1;
  max-height: 100% !important;
  height: 100% !important;
  @include ipad-portrait {
    max-width: 100% !important;
  }
}
.row {
  @include ipad-landscape {
    --bs-gutter-x: 0.7rem;
  }
  @include ipad-default-landscape {
    --bs-gutter-x: 0.8rem;
  }
  @media (min-width: 897px) and(max-width: 1023px) {
    flex-wrap: initial;
    margin-right: calc(var(--bs-gutter-x) / 2);
  }
}
.col-lg-3 {
  @media (min-width: 897px) and(max-width: 1023px) {
    width: auto;
  }
}
</style>
