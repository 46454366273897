import axios from 'axios'

export const getRequest = (path, params) => {
  return axios.get(path, {
    params
  })
}

export const postRequest = (path, data, params) => {
  return axios.post(path, data, {
    params
  })
}

export const putRequest = (path, data, params) => {
  return axios.put(path, data, {
    params
  })
}

export const deleteRequest = (path, data) => {
  return axios.delete(path, {
    data: data
  })
}

export const postRequestForFile = (path, data, params) => {
  return axios.post(path, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
    params
  })
}

// Add a request interceptor
axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config
  },
  function(error) {
    // Do something with request error
    // console.log('request interceptor error')
    return Promise.reject(error)
  }
)

// Add a response interceptor
axios.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log('response interceptor success')
    return response
  },
  function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // console.log('response interceptor error')
    return Promise.reject(error)
  }
)

export default {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  postRequestForFile
}
