<template>
  <div class="portfolio-carousel">
    <div class="portfolio">
      <span class="portfolio__btn" @click="navigateTo('/portfolio')">Portfolio</span>
      <div class="timerBar">
        <div class="timerProgress" :style="{ width: portfolioTimer * 20 + '%' }"></div>
      </div>
    </div>
    <div class="portfolio-section">
      <span
        class="portfolio-section__btn"
        @click="setStartingIndex('COMMERCE')"
        :class="{ active__btn: activeCommerce }"
        >COMMERCE</span
      >
      <span
        class="portfolio-section__btn media-class"
        @click="setStartingIndex('MEDIA &amp; COMMUNITY')"
        :class="{ active__btn: activeMedia }"
        >MEDIA & ENTERTAINMENT</span
      >
      <span
        class="portfolio-section__btn portfolio-section__btn-solution"
        @click="setStartingIndex('SOLUTION &amp; ENTERTAINMENT')"
        :class="{ active__btn: activeSolution }"
        >SOLUTION</span
      >
      <span
        class="portfolio-section__btn portfolio-section__btn-solution"
        @click="setStartingIndex('PRODUCT')"
        :class="{ active__btn: activeProduct }"
        >PRODUCT</span
      >
    </div>
    <div v-if="checkCompanyDetails" class="companyCarousel">
      <flickity ref="flickity" :options="flickityOptions">
        <div v-for="(item, index) in this.companyList" class="carousel-cell" :key="index">
          <img class="portfolio-card__image " alt="portfolio-card-image" v-lazy="baseUrl + item.field_carousal_logo" />
        </div>
        <div class="carousel-cell last">
          <img class="gdp-header__logo last" alt="gdp-logo" :src="require('@/assets/img/gdp-blue-logo.svg')" />
        </div>
      </flickity>
    </div>
  </div>
</template>
<script>
import { BACKEND_BASE_IMG_PATH } from '@/utils/constants.js'
import Flickity from 'vue-flickity'
export default {
  name: 'PortfolioCarousel',
  components: {
    Flickity
  },
  data() {
    return {
      flickityOptions: {
        groupCells: 1,
        initialIndex: 0,
        prevNextButtons: true,
        pageDots: false,
        contain: true,
        cellAlign: 'left'
      },
      currentActiveIndex: 0,
      portfolioTimer: 0,
      bannerImgUrl: '',
      bannerText: '',
      bannerButtonText: '',
      slideInterval: '',
      baseUrl: BACKEND_BASE_IMG_PATH
    }
  },
  props: {
    setBannerUrl: {
      type: Function,
      required: true
    },
    company: {
      type: Array,
      required: true
    },
    setScrollDirection: {
      type: Function,
      required: true
    }
  },
  methods: {
    setStartingIndex(sectionName) {
      const { companyList } = this
      if (!companyList || !Array.isArray(companyList)) return
      let updatedSection = ''
      let obj = {
        commerce: 'COMMERCE',
        media: 'MEDIA & COMMUNITY',
        solution: 'SOLUTION & ENTERTAINMENT',
        product: 'PRODUCT'
      }
      if (obj.media === sectionName) {
        updatedSection = 'MEDIA &amp; COMMUNITY'
      }
      if (obj.solution === sectionName) {
        updatedSection = 'SOLUTION &amp; ENTERTAINMENT'
      }
      if (obj.commerce === sectionName) {
        updatedSection = obj.commerce
      }
      if (obj.product === sectionName) {
        updatedSection = 'PRODUCT'
      }
      let resultIndex = companyList
        .map(item => {
          return item.field_category
        })
        .indexOf(updatedSection)
      if (resultIndex === -1) return
      this.$refs.flickity.select(resultIndex)
    },
    autoIncrementTimer() {
      if (this.portfolioTimer < 5) this.portfolioTimer += 0.01
      else {
        this.portfolioTimer = 0
        this.$refs.flickity.next(true, false)
      }
    },
    navigateTo(path) {
      this.$router.push(path, () => {})
    }
  },
  computed: {
    companyList() {
      const commerceData = this.company.filter(item => item.field_category === 'COMMERCE')
      const mediaData = this.company.filter(item => item.field_category === 'MEDIA &amp; COMMUNITY')
      const solutionData = this.company.filter(item => item.field_category === 'SOLUTION &amp; ENTERTAINMENT')
      const productData = this.company.filter(item => item.field_category === 'PRODUCT')
      const result = [...commerceData, ...mediaData, ...solutionData, ...productData]
      return result
    },
    checkCompanyDetails() {
      return !!this.company.length
    },
    startingMediaIndex() {
      const { companyList } = this
      if (!companyList || !Array.isArray(companyList)) return -1
      return companyList
        .map(item => {
          return item.field_category
        })
        .indexOf('MEDIA &amp; COMMUNITY')
    },
    startingSolutionIndex() {
      const { companyList } = this
      if (!companyList || !Array.isArray(companyList)) return -1
      return companyList
        .map(item => {
          return item.field_category
        })
        .indexOf('SOLUTION &amp; ENTERTAINMENT')
    },
    startingProductIndex() {
      const { companyList } = this
      if (!companyList || !Array.isArray(companyList)) return -1
      return companyList
        .map(item => {
          return item.field_category
        })
        .indexOf('PRODUCT')
    },
    activeCommerce() {
      return this.currentActiveIndex < this.startingMediaIndex
    },
    activeMedia() {
      return this.currentActiveIndex >= this.startingMediaIndex && this.currentActiveIndex < this.startingSolutionIndex
    },
    activeSolution() {
      return (
        this.currentActiveIndex >= this.startingSolutionIndex && this.currentActiveIndex < this.startingProductIndex
      )
    },
    activeProduct() {
      return this.currentActiveIndex >= this.startingProductIndex
    }
  },
  watch: {
    currentActiveIndex(newIndex, oldIndex) {
      this.setScrollDirection(newIndex > oldIndex)
    }
  },
  created() {
    this.slideInterval = window.setInterval(this.autoIncrementTimer, 10)
  },
  mounted() {
    this.setBannerUrl(
      this.companyList[0].field_video_poster_image,
      this.companyList[0].field_company_slug,
      this.companyList[0].title
    )
    this.$refs.flickity.on('change', index => {
      this.portfolioTimer = 0
      if (index == this.companyList.length) {
        this.$refs.flickity.select(0)
        this.currentActiveIndex = 0
      } else {
        this.currentActiveIndex = index
        this.bannerImgUrl = this.companyList[this.currentActiveIndex].field_video_poster_image
        this.bannerText = this.companyList[this.currentActiveIndex].field_company_slug
        this.bannerButtonText = this.companyList[this.currentActiveIndex].title
        this.setBannerUrl(this.bannerImgUrl, this.bannerText, this.bannerButtonText)
      }
    })
    this.$refs.flickity?.on('staticClick', (event, pointer, cellElement, cellIndex) => {
      this.$refs.flickity.select(cellIndex)
      this.$router.push({
        name: 'portfoliodetail',
        params: { currentIndex: this.companyList[this.currentActiveIndex].title }
      })
    })
  },
  beforeDestroy() {
    window.clearInterval(this.slideInterval)
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/colors';
@import '@/assets/scss/fonts';
@import '@/assets/scss/media-query-mixins';
.portfolio-carousel {
  display: flex;
  flex-wrap: wrap;
  background: transparent;
  justify-content: flex-end;
  @include iphone-portrait {
    flex-direction: column;
  }
  @include ipad-10-portrait {
    flex-direction: row !important;
    flex-wrap: wrap !important;
  }
  @include ipad-portrait {
    flex-direction: row !important;
    flex-wrap: wrap !important;
  }
  @media (min-width: 768px) and (max-width: 974px) {
    flex-direction: column;
  }
  .active__btn {
    font-size: 14px !important;
    opacity: 1 !important;
  }
  .portfolio {
    margin: 20px auto 20px 80px;
    color: $primary-white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include ipad-10-portrait {
      margin-left: 50px !important;
      order: initial !important;
    }
    @include iphone-portrait {
      margin-left: 16px;
      order: 1;
    }
    @include ipad-portrait {
      margin-left: 50px !important;
      order: initial !important;
    }
    &__btn {
      margin-right: 20px;
      font-size: 24px;
      font-family: GothamMedium;
      cursor: pointer;
      @include iphone-portrait {
        font-size: 20px;
        font-weight: 500;
      }
    }
    .timerBar {
      margin-top: 5px;
      width: 100px;
      position: relative;
      align-self: center;
      background-color: $scroll-indicator-background-color;
      height: 5px;
      border-radius: 10px;
    }
    .timerProgress {
      height: 5px;
      background-color: $primary-white;
      border-radius: 5px;
    }
  }
  .portfolio-section {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 20px 60px 20px 20px;
    @include ipad-10-portrait {
      margin-right: 30px;
      order: initial !important;
    }
    @include ipad-portrait {
      margin-right: 51px !important;
      order: initial !important;
    }
    @include iphone-portrait {
      margin: 30px 16px;
      justify-content: flex-start !important;
      order: 3;
      padding: 0 15px 0 0;
      width: 95vw;
      overflow: scroll;
    }
    @media (min-width: 768px) and (max-width: 974px) {
      order: 3;
      justify-content: flex-start !important;
      margin: 20px 20px 20px 80px;
    }
    &__btn {
      font-family: GothamMedium;
      font-size: 12px;
      letter-spacing: 1px;
      font-weight: bold;
      margin-right: 30px;
      opacity: 0.5;
      color: $primary-white;
      text-align: center;
      cursor: pointer;
      @include iphone-5se-potrait {
        font-size: 10px !important;
        letter-spacing: 0 !important;
      }
      @include ipad-10-portrait {
        margin-right: 20px !important;
      }
      @include iphone-portrait {
        font-size: 12px;
        font-family: GothamBook;
        letter-spacing: 0;
        margin-right: 14px;
      }
      @media (min-width: 320px) and(max-width:362px) {
        font-size: 12px;
        font-family: GothamBook;
      }
    }
    &__btn-solution {
      @include iphone-portrait {
        margin-right: 14px;
      }
      @include ipad-default-portrait {
        margin-right: 14px;
      }
    }
  }
  .companyCarousel {
    width: 100%;
    @include iphone-portrait {
      order: 2;
    }
    @include ipad-10-portrait {
      order: initial;
    }
    .carousel-cell {
      cursor: pointer;
      height: 108px;
      width: 175px;
      background-color: $primary-white;
      border-radius: 10px;
      margin-right: 10px;
      opacity: 0.5;
      &.is-selected {
        opacity: 1;
      }
    }
    .carousel-cell:last-child:after {
      /* this is your "last-animal" */
      display: none;
    }

    .portfolio-card__image {
      height: 108px;
      width: 175px;
    }
    .last {
      margin-right: 100px !important;
      pointer-events: none;
      opacity: 0;
      visibility: hidden;
    }
  }
  .media-class {
    min-width: max-content;
  }
  ::v-deep .flickity-slider {
    margin-left: 80px !important;
    // padding-right: 80px !important;
    // margin-right: 20px !important;
    @include ipad-10-portrait {
      margin-left: 50px !important;
    }
    @include iphone-portrait {
      margin-left: 16px !important;
    }
    @include ipad-default-portrait {
      margin-left: 50px !important;
    }
    @include ipad-portrait {
      margin-left: 50px !important;
    }
  }
}
</style>
