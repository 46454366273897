import { BACKEND_BASE_PATH } from './constants'

export default {
  home: {
    getCompany: `${BACKEND_BASE_PATH}/api/venture/company`,
    getInsights: `${BACKEND_BASE_PATH}/api/connect/fetch/insights`,
    getNews: `${BACKEND_BASE_PATH}/api/venture/news_carousal`,
    getLeader: `${BACKEND_BASE_PATH}/api/venture/leader`
  },
  about: {
    getCorporateVideo: `${BACKEND_BASE_PATH}/api/venture/about`,
    getBeliefCardDetails: `${BACKEND_BASE_PATH}/api/about/belief`
  },
  contact: {
    getContactDetails: `${BACKEND_BASE_PATH}/api/venture/contact`
  },
  footer: {
    getFooterLinks: `${BACKEND_BASE_PATH}/api/venture/footer`
  },
  search: {
    getSearchResult: searchQuery => `${BACKEND_BASE_PATH}/test/api/search/${searchQuery}`,
    getCompanyResult: searchQuery => `${BACKEND_BASE_PATH}/company/search/${searchQuery}`,
    getLeaderResult: searchQuery => `${BACKEND_BASE_PATH}/search/leader/${searchQuery}`
  },
  news: {
    getNewsCardData: `${BACKEND_BASE_PATH}/api/venture/news-paginated`,
    getArticle: `${BACKEND_BASE_PATH}/api/venture/news`
  },
  article: {
    getRecommendation: `${BACKEND_BASE_PATH}/api/venture/recommendation`
  },
  subscribe: {
    postSubscribeDetails: `${BACKEND_BASE_PATH}/api/post.json`
  }
}
