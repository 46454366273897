import Vue from 'vue'
import Vuex from 'vuex'
import home from './modules/home'
import about from './modules/about'
import contact from './modules/contact'
import footer from './modules/footer'
import search from './modules/search'
import news from './modules/news'
import article from './modules/article'
import subscribe from './modules/subscribe'
Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    home,
    about,
    contact,
    footer,
    search,
    news,
    article,
    subscribe
  }
})
