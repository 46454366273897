import api from '@/utils/http-api'
import apiConst from '@/utils/api-constants'

export default {
  namespaced: true,
  state: {
    recommendation: []
  },
  getters: {},
  mutations: {
    setRecommendation(state, payload) {
      state.recommendation = payload
    }
  },
  actions: {
    getRecommendation({ commit }, { count, index }) {
      const { getRecommendation } = apiConst.article
      api
        .postRequest(getRecommendation, {
          count,
          index
        })
        .then(response => {
          commit('setRecommendation', response.data)
        })
    }
  }
}
