<template>
  <div class="corporate-video">
    <div class="app-menu__header">
      <button class="app-menu-close" @click="closeMenu">&#10005;</button>
    </div>
    <video width="100%" height="100vh" controls autoplay class="corporate-video">
      <source :src="baseUrl + videoUrl" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
</template>
<script>
import { BACKEND_BASE_IMG_PATH } from '@/utils/constants.js'
export default {
  name: 'CorporateVideo',
  data() {
    return {
      baseUrl: BACKEND_BASE_IMG_PATH
    }
  },
  props: {
    closeMenu: {
      type: Function,
      required: true
    },
    videoUrl: {
      type: String,
      default: ''
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/colors.scss';
@keyframes mymoveleft {
  0% {
    opacity: 1;
    transform: scale(0.55, 0.8) translateX(36%);
  }
  100% {
    opacity: 1;
  }
}
@keyframes mymoveright {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  100% {
    transform: translatX(0);
    opacity: 1;
  }
}
.corporate-video {
  background-color: black;
  animation: mymoveleft 1.8s;
  position: fixed;
}
.corporate-video {
  width: 100vw;
  height: 100vh;
  left: 0 !important;
  top: 0 !important;
}
.app-menu-close {
  font-size: 30px;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 3%;
  top: 3%;
  border-radius: 50%;
  border: solid 0.5px $dark-blue-color;
  color: $dark-blue-color;
  background-color: transparent;
  cursor: pointer;
  z-index: 10;
}
.app-menu-close:hover {
  border: solid 3px $dark-blue-color;
  transition: 0.4s ease-in-out;
  background-color: $primary-white;
  font-size: 28px;
}
.app-menu-close .clicked {
  animation: mymoveright 1.2s;
}
</style>
